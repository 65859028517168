/* desktop-nav.css */

.desktop-nav {
    display: none;
    height: fit-content;
    padding-left: var(--unit6);
    padding-right: var(--unit6);
}

@media screen and (min-width: 992px) {
    .desktop-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--white);
        width: 100%;
    }
}

.desktop-nav-logo-and-stories {
    display: flex;
    align-items: center;
    gap: var(--unit5);
}

.desktop-nav-logo-and-stories a {
    display: flex;
    align-items: center;
}
.desktop-nav-list {
    display: flex;
    align-items: center;
    gap: var(--unit5);
    font-weight: 500;
}

.desktop-nav-list li {
    list-style: none;
    color: var(--purpleB);
}

.desktop-nav-list li a {
    color: var(--purpleB);
    text-decoration: none;
}