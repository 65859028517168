/* login.css */

.login-screen {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 480px) {
    .login-screen {
        min-height: calc(100vh - 200px);
    }
}

@media screen and (max-width: 480px) {
    .login-screen {
        padding-top: 50px;
    }
}
