/* how-it-works.css */

.how-it-works {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 992px) {
  .how-it-works {
    grid-template-columns: 1fr 1fr;
    gap: var(--unit6);
  }

  .how-it-works-sticky {
    position: sticky;
    top: var(--unit6);
  }
    
}