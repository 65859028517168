.toggle-button {
  width: var(--unit6);
  height: var(--unit5);
  border-radius: var(--unit5);
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: inset 0 3px 6px var(--greenD-25);
}
.toggle-button.on {
  background-color: var(--greenA);
}
.toggle-button.off {
  background-color: var(--purpleD-25);
}
.toggle-knob {
  width: var(--unit5);
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: var(--unit5);
  position: absolute;
  transform: translateX(0);
  transition: transform 0.3s;
  box-shadow: 0 3px 6px var(--greenD-25);
}
.toggle-button.on .toggle-knob {
  transform: translateX(var(--unit5));
}
