/* google-review-prompt.css */

.google-review-prompt {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: var(--unit4);
    z-index: 2;
    max-width: calc(var(--unit8) + var(--unit7));
    outline: 1px solid var(--greenD-10);
    border-radius: var(--unit4);
}

.google-review-image {
    max-width: 100%;
    margin-top: -60px;
    margin-bottom: -30px;
    pointer-events: none;
}