/* YardenTable.css */

.table-wrapper {
    box-shadow: 0 6px 12px var(--purpleC-10);
    max-height: calc(100vh - var(--unit8));
    overflow-x: auto;
    overflow-y: scroll;
    padding: initial;
    padding: unset;
    position: relative;
    width: 100%;
}

.table-wrapper table {
    border-collapse: collapse;
    border-radius: var(--unit4);
    text-align: left;
    width: 100%;
}

.table-wrapper table tbody td {
    background-color: transparent;
    padding: var(--unit3) var(--unit4);
    text-transform: capitalize;
    white-space: nowrap;
    font-size: var(--h6);
}

.table-wrapper table tbody td:first-of-type,
.table-wrapper table thead th:first-of-type {
    left: 0;
    position: sticky;
    z-index: 1;
}

.table-wrapper table tbody td:first-of-type:after,
.table-wrapper table thead th:first-of-type:after {
    background: var(--purpleD-10);
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
}

.table-wrapper table tbody td:first-of-type:before,
.table-wrapper table thead th:first-of-type:before {
    background: var(--purpleC-10);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
}

.table-wrapper table tbody tr {
    border-top: 1px solid var(--purpleC-10);
}

.table-wrapper table tbody tr:nth-child(even) {
    background: rgba(255, 255, 255, 0.5);
}

.table-wrapper table tbody tr:nth-child(odd) {
    background: #e6e5ea;
}

.table-wrapper table tbody tr:nth-child(even) td:first-of-type {
    background: #f9f9fa;
}

.table-wrapper table tbody tr:nth-child(odd) td:first-of-type {
    background: #e6e5ea;
}

.table-wrapper table thead {
    background: white;
    cursor: pointer;
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-wrapper table thead th {
    border-bottom: 1px solid var(--purpleC-10);
    padding: var(--unit3) var(--unit4);
    white-space: nowrap;
}

.table-wrapper table thead th:first-of-type {
    background: white;
    z-index: 1;
}

.table-wrapper table tfoot {
    border: 2px solid var(--greenD-25);
}

.table-wrapper table tfoot td {
    text-align: right;
    padding: var(--unit4);
}

.table-wrapper table tfoot th {
    border-right: 1px solid var(--greenD-10);
    padding: var(--unit4);
}
