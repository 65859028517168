.calendar {
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.weekdays,
.weekdays-mobile,
.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.weekdays div,
.weekdays-mobile div,
.days div {
    text-align: center;
    padding: 10px;
    border-radius: 4px;
}

.weekdays div,
.weekdays-mobile div {
    font-weight: bold;
    background-color: #f0f0f0;
}

.days .day {
    background-color: #fff;
}

.days .day.harvest {
    background-color: #d4edda;
    color: #155724;
}

@media screen and (max-width: 480px) {
    .weekdays {
        display: none;
    }
}

@media screen and (min-width: 481px) {
    .weekdays-mobile {
        display: none;
    }
}
