.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    appearance: none;
    width: 100%;
    height: 10px;
    background: #ddd; /* Track color */
    outline: none; /* Remove outline */
    transition: opacity 0.2s; /* Smooth transition */
    padding: 0px;
    border: none;
    box-shadow: none;
}

.slider::-moz-range-track {
    width: 100%;
    height: 10px;
    background: #2196f3; /* Track color */
    border-radius: 5px; /* Rounded corners */
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Knob width */
    height: 25px; /* Knob height */
    background: var(--purpleB); /* Knob color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 50%; /* Round knob */
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2); /* Shadow for better visibility */
    transition: background 0.2s; /* Smooth transition */
}

.slider::-moz-range-thumb {
    width: 25px; /* Knob width */
    height: 25px; /* Knob height */
    background: #4caf50; /* Knob color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 50%; /* Round knob */
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2); /* Shadow for better visibility */
    transition: background 0.2s; /* Smooth transition */
}

.slider-value {
    font-size: 16px;
    font-weight: bold;
}
