.mobile-nav {
    padding: var(--unit4);
    background-color: var(--white);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .mobile-nav {
        display: none;
    }
}

.mobile-nav-menu {
    background: rgba(255,255,255,0.9);
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh; 
    width: 100%;
    justify-content: center;
    align-items: center;
}

.mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    width: 100%;
    color: var(--purpleB);
}

.mobile-nav-list li {
padding: var(--unit4) var(--unit6);
width: 100%;
cursor: pointer;
}

.hamburger-btn {
    padding: 0 !important;
    min-width: unset;
    box-shadow: none;
    border: none;
}

.hamburger-btn-icon {
    aspect-ratio: 5/4;
    height: var(--unit4);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.hamburger-btn-icon span {
    height: var(--unit2);
    width: 100%;
    background: var(--purpleB);
    border-radius: var(--unit1);
}

.mobile-nav-close-btn {
    position: absolute;
    top: var(--unit4);
    right: var(--unit5);
}