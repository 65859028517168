/* customer-plant-favorites.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.plant-selection-grid {
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--unit7) + var(--unit6)), 1fr)
    );
}

.plant-selection-grid .card {
    transition: 0.3s;
    outline: 0 solid transparent;
    background: transparent;
    filter: grayscale(1);
    box-shadow: none;
    outline: 1px solid var(--greenD-10);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--unit4);
    padding: var(--unit4);
    cursor: pointer;
}

.plant-selection-grid .card.checked {
    background: white;
    filter: grayscale(0);
    outline: none;
}

.plant-selection-grid .card:hover {
    outline: 1px solid var(--purple2);
    transition: none;
    transform: scale(1.025);
    filter: grayscale(0);
    box-shadow: 0 6px 12px var(--greenD-10);
}

.plant-selection-grid .card:active {
    transform: scale(1);
    transition: none;
    background: var(--purple5);
}

.plant-selection-grid .card:has(input[type='checkbox']:checked):hover {
    outline: 1px solid var(--purple2);
}
