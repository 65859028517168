/* OrderDetails.css */

.data-detail {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.harvest-indicator {
    background-color: lightblue;
}
