/* customer-orders.css */

#customer-orders {
    padding: var(--unit4) 0 var(--unit4) 0;
    max-height: calc(100vh - 129px);
    padding-bottom: var(--unit5);
    overflow-y: auto;
}

.orders-container {
    display: flex;
    flex-direction: column;
    gap: var(--unit4);
    background: var(--greenD-10);
    padding: var(--unit4);
    max-height: calc(100vh - 322px);
    border-radius: var(--unit4);
    box-shadow: inset 0 6px 12px var(--greenD-10);
    overflow-y: auto;
}

@media screen and (min-width: 768px) {
    #customer-orders {
        overflow-y: unset;
    }

    .orders-container {
        max-height: calc(100vh - 174px);
    }
}

.column-empty {
    display: flex;
    justify-content: center;
    background-color: var(--greenD-10);
    align-items: center;
    height: var(--unit7);
    border: 1px solid var(--greenD-25);
    border-radius: var(--unit3);
}

.order-count {
    border-radius: var(--unit2);
    display: flex;
    align-items: center;
}

.order-card {
    background: white;
    border-radius: var(--unit3);
    padding: var(--unit4) var(--unit4) var(--unit5) var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
    gap: var(--unit4);
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.order-card-container {
    transition: 0.3s;
    transform: scale(1);
}
.order-card-container:hover {
    transform: scale(1.01);
    transition: none;
}

.order-card-header {
    display: flex;
    gap: var(--unit4);
    flex-wrap: wrap;
    justify-content: space-between;
}

.order-card-header .info {
    display: flex;
    flex-direction: column;
}

.order-card-header .info data {
    font-size: var(--h6);
    height: var(--h5);
    display: flex;
    align-items: center;
    padding-top: var(--unit4);
}

.vendor-info {
    display: flex;
    align-items: center;
    gap: var(--unit3);
}

.order-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    width: 100%;
    overflow: hidden;
}

.order-card .avatar,
.customer-order-details .avatar {
    border-radius: 50%;
    height: var(--unit5);
    aspect-ratio: 1/1;
    object-fit: cover;
    border: 2px solid var(--purpleB);
}
