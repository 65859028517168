@import url('https://use.typekit.net/fug0qzw.css');

/* data font adjustments */
@font-face {
    font-family: 'antarctican-mono';
    src:
        url('https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    ascent-override: 100%;
    descent-override: 70%;
    /* line-gap-override: 0%; */
}

:root {
    /* colors */
    --black: #2f3033;
    --blackTrans: rgba(0, 0, 0, 0.16);
    --blackOverlay: rgba(0, 0, 0, 0.8);
    --white: #ffffff;
    --whiteTrans: rgba(255, 255, 255, 0.95);
    --gray1: #f7f7f7;
    --gray2: #dfdfdf;
    --gray3: #737373;
    --brown1: #d5c1ac;
    --brown2: #f6f2ee;
    --brown3: #55402b;
    --green1: #21410b;
    --green2: #4d991a;
    --green3: #c7eeae;
    --red1: #ff4d4d;
    --yellow1: #ffff66;
    --orange1: #ff9900;
    --blue1: #509cff;

    /* outlines and borders */
    --outlineDefault: 1px solid var(--black);
    --outlineOffsetDefault: -1px;
    --outlineDashed: 1px dashed var(--black);
    --borderDefault: var(--outlineDefault);

    /* box shadows */
    --boxShadowDown: 0 6px 12px var(--greenD-10);
    --boxShadowUp: 0 -6px 12px var(--greenD-10);
    --boxShadowLeft: -6px 0 12px var(--greenD-10);
    --boxShadowRight: 6px 0 12px var(--greenD-10);

    --default-font: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
        Sans-Serif;
    --paragraph-font: 'pragmatica', helvetica, arial, sans-serif;
    --defaultFont: var(--default-font);
    --data-font: 'antarctican-mono', sans-serif;
    --white: #fff;
    --white-10: hsla(0, 0%, 100%, 0.1);
    --white-25: hsla(0, 0%, 100%, 0.25);
    --white-50: hsla(0, 0%, 100%, 0.5);
    --white-75: hsla(0, 0%, 100%, 0.75);
    --white-90: hsla(0, 0%, 100%, 0.9);

    --hue: 120;
    --saturation: 100%;
    --lightness: 50%;
    --alpha: 1;
    --greenE: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        var(--alpha)
    );
    --greenE-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.95
    );
    --greenE-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.9
    );
    --greenE-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.75
    );
    --greenE-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.5
    );
    --greenE-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.25
    );
    --greenE-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.1
    );
    --greenE-5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.05
    );
    --greenD: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        var(--alpha)
    );
    --greenD-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.95
    );
    --greenD-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.9
    );
    --greenD-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.75
    );
    --greenD-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.5
    );
    --greenD-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.25
    );
    --greenD-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.1
    );
    --greenD-5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.05
    );
    --greenC: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        var(--alpha)
    );
    --greenC-5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.05
    );
    --greenC-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.1
    );
    --greenC-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.25
    );
    --greenC-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.5
    );
    --greenC-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.75
    );
    --greenC-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.9
    );
    --greenC-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.95
    );

    --greenB: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        var(--alpha)
    );
    --greenB-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.1
    );
    --greenB-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.25
    );
    --greenB-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.5
    );
    --greenB-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.75
    );
    --greenB-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.9
    );
    --greenB-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.95
    );
    --greenA: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 10%),
        var(--alpha)
    );
    --green0: hsla(
        var(--hue),
        var(--saturation),
        var(--lightness),
        var(--alpha)
    );
    --green1: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 10%),
        var(--alpha)
    );
    --green2: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 20%),
        var(--alpha)
    );
    --green3: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 30%),
        var(--alpha)
    );

    --green4: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        var(--alpha)
    );
    --green4-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.1
    );
    --green4-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.25
    );
    --green4-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.5
    );
    --green4-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.75
    );
    --green4-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.9
    );
    --green4-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.95
    );

    --green5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        var(--alpha)
    );
    --green5-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.1
    );
    --green5-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.25
    );
    --green5-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.5
    );
    --green5-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.75
    );
    --green5-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.9
    );
    --green5-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.95
    );

    --purpleE: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 45%),
        var(--alpha)
    );

    --purpleD: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        var(--alpha)
    );
    --purpleD-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.1
    );
    --purpleD-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.25
    );
    --purpleD-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.5
    );
    --purpleD-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.75
    );
    --purpleD-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.9
    );
    --purpleD-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.95
    );

    --purpleC: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        var(--alpha)
    );
    --purpleC-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        10%
    );
    --purpleC-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        25%
    );
    --purpleC-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        50%
    );
    --purpleC-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        75%
    );
    --purpleC-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        90%
    );

    --purpleB: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 20%),
        var(--alpha)
    );
    --purpleB-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.95
    );
    --purpleB-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.9
    );
    --purpleB-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.75
    );
    --purpleA: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 10%),
        var(--alpha)
    );
    --purple0: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        var(--lightness),
        var(--alpha)
    );
    --purple1: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 10%),
        var(--alpha)
    );
    --purple2: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 20%),
        var(--alpha)
    );

    --purple3: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        var(--alpha)
    );
    --purple3-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.1
    );
    --purple3-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.25
    );
    --purple3-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.5
    );
    --purple3-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.75
    );
    --purple3-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.9
    );
    --purple3-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.95
    );

    --purple4: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        var(--alpha)
    );
    --purple4-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.1
    );
    --purple4-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.25
    );
    --purple4-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.5
    );
    --purple4-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.75
    );
    --purple4-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.9
    );
    --purple4-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.95
    );

    --purple5: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        var(--alpha)
    );
    --purple5-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.1
    );
    --purple5-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.25
    );
    --purple5-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.5
    );
    --purple5-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.75
    );
    --purple5-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.9
    );
    --purple5-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.95
    );

    --blue0: hsla(
        calc(var(--hue) + 90),
        var(--saturation),
        var(--lightness),
        var(--alpha)
    );

    --blue1: hsla(
        calc(var(--hue) + 90),
        var(--saturation),
        calc(var(--lightness) + 10%),
        var(--alpha)
    );

    --unitScaleRatio: 2;
    --fontScaleRatio: calc(1 + (1 / 3));
    --headerLineHeight: calc(1 - (1 / 6));
    --paragraphLineHeight: calc(1 + (1 / 3));

    --unit0: 1px;
    --unit1: calc(var(--unit0) * var(--unitScaleRatio));
    --unit2: calc(var(--unit1) * var(--unitScaleRatio));
    --unit3: calc(var(--unit2) * var(--unitScaleRatio));
    --unit4: calc(var(--unit3) * var(--unitScaleRatio));
    --unit5: calc(var(--unit4) * var(--unitScaleRatio));
    --unit6: calc(var(--unit5) * var(--unitScaleRatio));
    --unit7: calc(var(--unit6) * var(--unitScaleRatio));
    --unit8: calc(var(--unit7) * var(--unitScaleRatio));
    --unit9: calc(var(--unit8) * var(--unitScaleRatio));

    --h7: calc(var(--unit4) / var(--fontScaleRatio));
    --h6: calc(var(--unit4));
    --h5: calc(var(--h6) * var(--fontScaleRatio));
    --h4: calc(var(--h5) * var(--fontScaleRatio));
    --h3: calc(var(--h4) * var(--fontScaleRatio));
    --h2: calc(var(--h3) * var(--fontScaleRatio));
    --h1: calc(var(--h2) * var(--fontScaleRatio));

    --fontSizeLabel: calc(var(--fontSizeMin) / var(--fontScaleRatio));
    --whole: 100%;
    --half: 50%;
    --third: calc(var(--whole) / 3);
    --twoThirds: 0.66667;
    --quarter: 25%;
    --fourth: 25%;
    --threeQuarters: 75%;
    --threeFourths: 75%;
    --fifth: 20%;
    --twoFifths: 40%;
    --threeFifths: 60%;
    --fourFifths: 80%;
    --sixth: calc(var(--whole) / 6);
    --fiveSixths: calc(var(--sixth) * 5);
    --seventh: calc(var(--whole) / 7);
    --twoSevenths: calc(var(--seventh) * 2);
    --threeSevenths: calc(var(--seventh) * 3);
    --fourSevenths: calc(var(--seventh) * 4);
    --fiveSevenths: calc(var(--seventh) * 5);
    --sixSevenths: calc(var(--seventh) * 6);
    --eighth: 12.5%;
    --threeEighths: calc(var(--eighth) * 3);
    --fiveEigths: calc(var(--eighth) * 5);
    --sevenEighths: calc(var(--eighth) * 7);
    --ninth: calc(var(--whole) / 9);
    --twoNinths: calc(var(--ninth) * 2);
    --fourNinths: calc(var(--ninth) * 4);
    --fiveNinths: calc(var(--ninth) * 5);
    --sevenNinths: calc(var(--ninth) * 7);
    --eightNinths: calc(var(--ninth) * 8);
    --tenth: 10%;
    --eleventh: calc(var(--whole) / 11);
    --twelvth: calc(var(--whole) / 12);
    --thirteenth: calc(var(--whole) / 13);
    --fourteenth: calc(var(--whole) / 14);
    --fifteenth: calc(var(--whole) / 15);
    --sixteenth: calc(var(--whole) / 16);

    --breakpoint-xs: 480px;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1280px;
    --breakpoint-hg: 1366px;
    --breakpoint-xh: 1440px;
    --breakpoint-gi: 1536px;
    --breakpoint-xg: 1680px;
    --breakpoint-mg: 1920px;
    --breakpoint-mgXL: 2560px;
    color: var(--greenE-90);
    font-family: var(--defaultFont);
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.cursive {
    font-family: 'Cedarville Cursive', cursive !important;
}

body {
    margin: 0px;
    background-color: var(--white);
    font-family: var(--defaultFont);
}

body * {
    box-sizing: border-box;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

a {
    cursor: pointer;
    color: var(--blue1);
    font: var(--default-font);
}

a.small {
    font-size: var(--h6);
}

::-webkit-scrollbar {
    width: var(--unit3); /* Remove scrollbar space */
    height: var(--unit3); /* Remove scrollbar space */
    background: var(--greenD-10); /* Make scrollbar Y invisible */
}

::-webkit-scrollbar-thumb {
    background: var(--greenD-10);
    border-radius: var(--unit4);
}

/* padding */
.p0 {
    padding: 0px !important;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15,
.p4 {
    padding: var(--unit4);
}

.p20 {
    padding: 20px;
}

.p25 {
    padding: 25px;
}

.p50 {
    padding: 50px;
}

.p100 {
    padding: 100px;
}

.pt0 {
    padding-top: 0px !important;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt15,
.pt4 {
    padding-top: var(--unit4);
}

.pt20 {
    padding-top: 20px;
}

.pt25 {
    padding-top: 25px !important;
}

.pt35 {
    padding-top: 35px !important;
}

.pt50 {
    padding-top: 50px;
}

.pt75 {
    padding-top: 75px;
}

.pt100 {
    padding-top: 100px;
}

.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl15,
.pl4 {
    padding-left: var(--unit4);
}

.pl20 {
    padding-left: 20px;
}

.pl25 {
    padding-left: 25px;
}

.pl50 {
    padding-left: 50px;
}

.pb0 {
    padding-bottom: 0px !important;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15,
.pb4 {
    padding-bottom: var(--unit4);
}

.pb20 {
    padding-bottom: 20px;
}

.pb25 {
    padding-bottom: 25px;
}

.pb50 {
    padding-bottom: 50px;
}

.pb75 {
    padding-bottom: 75px;
}

.pb100 {
    padding-bottom: 100px;
}

.pr5 {
    padding-right: 5px;
}

.pr10 {
    padding-right: 10px;
}

.pr15,
.pr4 {
    padding-right: var(--unit4);
}

.pr20 {
    padding-right: 20px;
}

.pr25 {
    padding-right: 25px;
}

.pr50 {
    padding-right: 50px;
}

/* margin */

.m0 {
    margin: 0px;
}

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.m15,
.m4 {
    margin: var(--unit4);
}

.m20 {
    margin: 20px;
}

.m25 {
    margin: 25px;
}

.m50 {
    margin: 50px;
}

.mt0 {
    margin-top: 0px;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt4,
.mt15 {
    margin-top: var(--unit4); /* used to be 15px */
}

@media (max-width: 600px) {
    .mt3-on-mobile {
        margin-top: 15px;
    }
}

.mt20 {
    margin-top: 20px;
}

.mt25 {
    margin-top: 25px;
}

.mt50 {
    margin-top: 50px;
}

.mt75 {
    margin-top: 75px;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml15,
.ml4 {
    margin-left: var(--unit4);
}

.ml20 {
    margin-left: 20px;
}

.ml25 {
    margin-left: 25px;
}

.ml50 {
    margin-left: 50px;
}

.mb0 {
    margin-bottom: 0px;
}

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15,
.mb4 {
    margin-bottom: var(--unit4);
}

.mb20 {
    margin-bottom: 20px;
}

.mb25 {
    margin-bottom: 25px;
}

.mb50 {
    margin-bottom: 50px;
}

.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px !important;
}

.mr15 {
    margin-right: 15px;
}

@media (max-width: 600px) {
    .no-mr15-on-mobile {
        margin-right: 0px;
    }
}

.mr20 {
    margin-right: 20px;
}

.mr25 {
    margin-right: 25px;
}

.mr50 {
    margin-right: 50px;
}

/* font-size */
.text-small {
    font-size: 0.875rem !important;
}

/* text alignment */
.text-center {
    text-align: center;
}

.text-left {
    text-align: center;
}

.text-right {
    text-align: right;
}

/* block */
.block {
    display: block !important;
}

.inline-block {
    display: inline-block;
}

/* positioning */
.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.fixed {
    position: fixed !important;
}

.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.top0 {
    top: 0;
}

/* centering with flexbox */
.flex {
    display: flex;
}

@media (max-width: 600px) {
    .no-flex-on-mobile {
        display: initial;
    }
}

.flex-justify-center,
.flex-center-x {
    /* centers elements horizontally */
    justify-content: center;
    display: flex;
}

.flex-align-center,
.flex-center-y {
    /* centers elements vertically */
    align-items: center;
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-center {
    /* centers elements vertically and horizontally */
    justify-content: center;
    align-items: center;
    display: flex;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-unitaround {
    display: flex;
    justify-content: unitaround;
}
.flex-unitbetween {
    display: flex;
    justify-content: unitbetween;
}

.flex-grow {
    flex-grow: 1;
}

.flex-1 {
    flex: 1;
}

.align-self-center {
    align-self: center;
}

/* makes an element appear disabled or faded */
.disabled {
    opacity: 0.4;
    pointer-events: none;
}

/* text transforms */
.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.regularcase {
    text-transform: none;
}

.link {
    color: var(--blue1);
    cursor: pointer;
    font-size: var(--h6);
}

/* text truncation */
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* overflow */
.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

/* outlines */
.no-outline {
    outline-width: 0px;
}

.no-underline {
    text-decoration: none;
}

.underline-on-hover:hover {
    text-decoration: underline;
}

/* floats */
.float-right {
    float: right !important;
}

.float-left {
    float: left;
}

/* background colors */
.bg-white {
    background-color: var(--white) !important;
}

.bg-green1 {
    background-color: var(--green1) !important;
}

.bg-green2 {
    background-color: var(--green2);
}

.bg-green3 {
    background-color: var(--green3);
}

.bg-gray1 {
    background-color: var(--gray1) !important;
}

.bg-gray2 {
    background-color: var(--gray2);
}

.bg-gray3 {
    background-color: var(--gray3);
}

.bg-gray4 {
    background-color: '#E6E5EA';
}

.bg-brown1 {
    background-color: var(--brown1) !important;
}

.bg-brown2 {
    background-color: var(--brown2) !important;
}

.bg-brown3 {
    background-color: var(--brown3) !important;
}

.bg-yellow1 {
    background-color: var(--yellow1) !important;
}

.bg-orange1 {
    background-color: var(--orange1) !important;
}

.bg-blue1 {
    background-color: var(--blue1) !important;
}

.bg-red1 {
    background-color: var(--red1) !important;
}

.bg-green0 {
    background-color: var(--green0);
}

.bg-purpleB90 {
    background-color: var(--purpleB-90) !important;
}

/* text colors */
.text-white {
    color: var(--white) !important;
}

.text-black {
    color: var(--black) !important;
}

.text-red {
    color: red !important;
}

.text-green1 {
    color: var(--green1);
}

.text-green2 {
    color: var(--green2);
}

.text-green3 {
    color: var(--green3);
}

.text-gray1 {
    color: var(--gray1);
}

.text-gray2 {
    color: var(--gray2);
}

.text-gray3 {
    color: var(--gray3);
}

.text-brown1 {
    color: var(--brown1) !important;
}

.text-brown2 {
    color: var(--brown2);
}

.text-brown3 {
    color: var(--brown3) !important;
}

.text-yellow1 {
    color: var(--yellow1);
}

.text-red1 {
    color: var(--red1) !important;
}

.text-blue1 {
    color: var(--blue1) !important;
}

.text-purple0 {
    color: var(--purple0);
}

.text-purpleB90 {
    color: var(--purpleB-90);
}

.text-purpleB {
    color: var(--purpleB);
}

.text-green0 {
    color: var(--green0);
}

/* border colors */
.border-bottom-purpleC-10 {
    border-bottom: 1px solid var(--purpleC-10) !important;
}

/* cursor */
.pointer {
    cursor: pointer;
}

/* z-index */
.z0 {
    z-index: 0;
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

.z3 {
    z-index: 3 !important;
}

.z4 {
    z-index: 4 !important;
}

/* box-shadow */
.shadow {
    box-shadow: var(--boxShadowDown) !important;
}

/* width */
.full {
    width: 100% !important;
}

@media (max-width: 600px) {
    .full-on-mobile {
        width: 100% !important;
    }
}

.full-span {
    width: 100vw !important;
}

.fit-content {
    width: fit-content !important;
}

/* height */
.full-height {
    height: 100%;
}

.full-view {
    height: 100vh;
}

.min-full-view {
    min-height: 100vh;
}

.half {
    width: 50% !important;
}

.fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.max-content {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
}

/* border-radius */
.round {
    border-radius: 50%;
}

.curved {
    border-radius: 5px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container input {
    width: auto !important;
    border: none;
    /* text-align: center; */
}

/* opacity  */
.opacity01 {
    opacity: 0.1;
}

.opacity02 {
    opacity: 0.2;
}

.opacity03 {
    opacity: 0.3;
}

.opacity04 {
    opacity: 0.4;
}

.opacity05 {
    opacity: 0.5;
}

.opacity06 {
    opacity: 0.6;
}

.opacity07 {
    opacity: 0.7;
}

.opacity08 {
    opacity: 0.8;
}

.opacity09 {
    opacity: 0.9;
}

/* font-weigth */
.fw100 {
    font-weight: 100 !important;
}

section {
    max-width: 1200px;
    margin: 0 auto;
    padding-inline: var(--unit4);
}

@media screen and (min-width: 480px) {
    section {
        padding-inline: var(--unit5);
    }
}

@media screen and (min-width: 992px) {
    section {
        padding-inline: var(--unit6);
    }
}

@media screen and (min-width: 1260px) {
    section {
        padding-inline: 0;
    }
}

/* app  */
.section1-title {
    font-size: 60px;
}

.dollar-sign {
    position: relative;
    top: -12px;
}

.dollar-sign-2 {
    position: relative;
    top: -6px;
}

.content-container {
    display: flex;
    gap: var(--unit4);
    align-items: flex-end;
}

.app-root header {
    grid-area: header;
}

.app-root main {
    grid-area: main;
}

.app-root footer {
    grid-area: footer;
}

.app-root {
    display: grid;
    grid-template-areas:
        'header'
        'main'
        'footer';
}

.hidden {
    display: none !important;
}

.MuiInput-underline {
    font-family: var(--default-font) !important;
}

.MuiInput-underline:before {
    border-bottom: unset !important;
}

.MuiInput-underline:after {
    border-bottom: unset !important;
}

button {
    background: var(--green0);
    border: 1px solid var(--green2);
    border-top-color: var(--green3);
    border-bottom-color: var(--greenA);
    padding: var(--unit4) var(--unit5);
    border-radius: var(--unit5);
    text-transform: capitalize;
    box-shadow: 0 3px 6px
        hsla(
            var(--hue),
            var(--saturation),
            calc(var(--lightness) - 10%),
            calc(var(--alpha) - 0.85)
        );
    font-weight: 600;
    color: var(--purpleB);
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

button:hover {
    background: var(--green2);
    transform: scale(1.025);
}

button:active {
    transform: scale(1);
}

button.small {
    padding: var(--unit3) var(--unit4);
    font-size: var(--h6);
    border-radius: var(--unit5);
    text-transform: capitalize;
    min-width: calc(var(--unit6) + var(--unit5));
    display: flex;
    justify-content: center;
    align-items: center;
}

button.btn3 {
    border-color: var(--white-50);
    border-top-color: var(--white);
    border-bottom-color: var(--greenC-10);
}

button.purple {
    background: var(--purpleB);
    color: white;
}

button.purple:hover {
    background-color: var(--purpleA);
}

button.btn2,
button.btn3,
input::file-selector-button {
    background: white;
}

button.btn4 {
    background: white;
    border: none;
    box-shadow: none;
}

data {
    font-family: var(--data-font);
}

p {
    font-family: 'pragmatica', helvetica, arial, sans-serif;
    font-size: var(--h5);
    line-height: calc(3 / 2);
    margin-top: 0;
}

small {
    font-family: 'pragmatica', helvetica, arial, sans-serif;
    font-size: var(--h6);
    line-height: calc(3 / 2);
    display: block;
}

small + small,
p + p {
    margin-bottom: calc(1em * 3 / 2);
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1;
    margin: 0;
    padding: 0;
    /* color: var(--purpleC); */
}

/* auto grid */
/* auto grid */

.auto-grid-5,
.auto-grid-small,
.auto-grid,
.auto-grid-medium,
.auto-grid-6,
.auto-grid-large,
.auto-grid-7 {
    display: -ms-grid;
    display: grid;
    grid-gap: var(--unit5);
}

.auto-grid-5,
.auto-grid-small {
    grid-gap: var(--unit2);
    grid-template-columns: repeat(auto-fill, minmax(var(--unit7), 1fr));
}

.auto-grid,
.auto-grid-6,
.auto-grid-medium {
    grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--unit8) + var(--unit6)), 1fr)
    );
}

.auto-grid-7,
.auto-grid-large {
    grid-template-columns: 1fr;
    grid-gap: var(--unit5);
}

@media screen and (min-width: 786px) {
    .auto-grid-7,
    .auto-grid-large {
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--unit6);
    }
}

select {
    padding: var(--unit3) var(--unit5) var(--unit3) var(--unit4);
    border-radius: var(--unit5);
    border-bottom: none;
    box-shadow: 0 6px 12px var(--purpleD-10);
    background-color: white;
    color: var(--purpleD-75);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: center;
    width: fit-content;
    min-width: var(--unit6);
}

input,
textarea {
    font-family: var(--paragraph-font);
}

input[type='date'] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type='date']:before {
    content: attr(placeholder) !important;
    color: var(--greenC-25);
}

input[type='date']:focus:before,
input[type='date']:valid:before {
    content: '';
}

input:focus {
    outline: 2px solid var(--purpleB);
    outline: offset;
    outline-offset: -1px;
    background: var(--green4) !important;
}

textarea:focus {
    outline: 2px solid var(--purple0);
    outline: offset;
    outline-offset: -1px;
    background: var(--green4);
}

::-webkit-input-placeholder,
input[type='date']::-webkit-input-placeholder {
    color: var(--greenD-25);
}

::-moz-placeholder,
input[type='date']::-moz-placeholder {
    color: var(--greenD-25);
}

:-ms-input-placeholder,
input[type='date']:-ms-input-placeholder {
    color: var(--greenD-25);
}

::-ms-input-placeholder,
input[type='date']::-ms-input-placeholder {
    color: var(--greenD-25);
}

::placeholder,
input[type='date']::placeholder {
    color: var(--greenD-25);
}

a:has(button) {
    text-decoration: none;
}

.p-none {
    padding: none;
}

.p0 {
    padding: var(--unit0);
}

.p1 {
    padding: var(--unit1);
}

.p2 {
    padding: var(--unit2);
}

.p3 {
    padding: var(--unit3);
}

.p4 {
    padding: var(--unit4);
}

.p5 {
    padding: var(--unit5);
}

.p6 {
    padding: var(--unit6);
}

.p7 {
    padding: var(--unit7);
}

.p8 {
    padding: var(--unit8);
}

.p9 {
    padding: var(--unit9);
}

hr {
    border: none;
    border-top: 1px solid var(--greenD-10);
}

/* general radio button */
input[type='radio'] {
    all: unset;
    height: var(--unit5);
    width: var(--unit5);
    background: var(--greenD-10);
    border: 2px solid var(--purpleB);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 6px 12px var(--greenD-25);
}

input[type='radio']:hover {
    background: var(--green4);
    transform: scale(1.05);
}

input[type='radio']:checked {
    background: var(--green4) !important;
}

input[type='radio']:checked::before {
    content: '';
    background: var(--purpleB);
    height: var(--unit4);
    width: var(--unit4);
    position: absolute;
    border-radius: var(--unit3);
    box-shadow: 0 3px 6px var(--purpleC-50);
}

/* general checkbox */
input[type='checkbox'] {
    all: unset;
    height: calc(var(--unit4) + var(--unit3));
    width: calc(var(--unit4) + var(--unit3));
    background: var(--greenD-10);
    border: 2px solid var(--purpleB);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 6px 12px var(--greenD-25);
}

input[type='checkbox']:hover {
    background: var(--green4);
    transform: scale(1.05);
}

input[type='checkbox']:checked {
    background: var(--green4) !important;
}

input[type='checkbox']:checked::before {
    content: '';
    border-bottom: 3px solid var(--purpleB);
    border-left: 3px solid var(--purpleB);
    height: var(--unit2);
    width: var(--unit3);
    transform: rotate(-45deg) scale(1.5) translate(1px, -1px);
    position: absolute;
}

.card {
    padding: var(--unit4);
    background: var(--white);
    border-radius: var(--unit4);
    box-shadow: var(--boxShadowDown);
}

.flex {
    display: flex;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-center-y {
    align-items: center;
}
