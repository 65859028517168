.message-bubble {
    position: relative;
    background-color: var(--purple4);
    padding: 10px 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

/* General styles for the sections */
.maintenance-results-section {
    padding: 10px;
    box-sizing: border-box;
}

/* Style for the container and rows */
.maintenance-results-gallery-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px; /* Adjust as needed */
}

.maintenance-results-row {
    display: flex;
    flex-wrap: wrap;
}

/* Style for screens larger than 960px */
@media (min-width: 960px) {
    .maintenance-results-section {
        width: 100%;
    }
}

/* Style for screens smaller than 960px */
@media (max-width: 959px) {
    .maintenance-results-section {
        width: 100%;
    }
}

.maintenance-results-img-container {
    position: relative;
    width: 100%;
    padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3) */
    overflow: hidden;
}

.maintenance-results-img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
