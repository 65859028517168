/* account.css */

html:has(#customer-dashboard) {
    overflow: hidden;
    color: var(--greenD-75);
}

#customer-dashboard {
    display: grid;
    grid-template-areas:
        'customer-header'
        'customer-content';
    grid-template-columns: 1fr;
    background-color: var(--purpleD-10);
    min-height: 100vh;
    min-height: 100dvh;
}

#customer-dashboard-header {
    grid-area: customer-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--greenD-10);
}

#customer-dashboard-content {
    grid-area: customer-content;
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-areas: 'customer-sidebar customer-main';
    width: 100%;
    padding: 0;
}

#customer-dashboard-main {
    grid-area: customer-main;
    padding: var(--unit3);
    height: calc(100vh - 54px);
    height: calc(100dvh - 54px);
    overflow: auto;
    padding-bottom: var(--unit4);
}

@media screen and (min-width: 576px) {
    #customer-dashboard-main {
        padding: var(--unit4);
        /* padding-bottom: var(--unit7); */

    }
}

.customer-dashboard-main-content {
    --header-height: 66px;
    height: calc(100dvh - var(--header-height));
}

#customer-dashboard-sidebar {
    grid-area: customer-sidebar;
    border-right: 1px solid var(--greenD-10);
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 576px) {
    #customer-dashboard-header {
        padding: var(--unit3);
    }

    .customer-dashboard-main-content {
        height: calc(100dvh - var(--header-height) + 7px);
    }
}

@media screen and (min-width: 992px) {
    #customer-dashboard-main {
        padding: var(--unit4) var(--unit4) var(--unit4) var(--unit5);
        /* padding-bottom: var(--unit7); */
    }
}

#sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: var(--unit4) 0;
    gap: var(--unit4);
}

#sidebar-header .user-name {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    width: unset;
    height: unset;
    padding: 0;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

@media screen and (min-width: 768px) {
    #sidebar-header .user-name {
        -webkit-writing-mode: unset;
        writing-mode: unset;
        transform: scale(1);
    }
}

#sidebar-header .avatar {
    width: var(--unit5);
    height: var(--unit5);
    border-radius: 50%;
    background: var(--purpleB);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#customer-dashboard-sidenav {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
}

#customer-dashboard-sidenav li {
    padding: var(--unit3);
    border-top: 1px solid var(--greenD-10);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#customer-dashboard-sidenav li .link-title {
    font-size: var(--h7);
    text-transform: capitalize;
}

#customer-dashboard-sidenav li:hover {
    background: var(--greenD-10);
}

#customer-dashboard-sidenav .sidenav-icon {
    width: var(--h5);
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    #customer-dashboard-sidenav li {
        flex-direction: row;
        gap: var(--unit3);
        justify-content: flex-start;
        padding: var(--unit3) var(--unit4);
    }

    #customer-dashboard-sidenav li .link-title {
        font-size: var(--h6);
    }
}

@media screen and (min-width: 992px) {
    #customer-dashboard-sidenav li {
        flex-direction: row;
        gap: var(--unit4);
        justify-content: flex-start;
        padding: var(--unit3) var(--unit4);
    }

    #customer-dashboard-sidenav li .link-title {
        font-size: var(--h5);
    }
}

#customer-dashboard h1,
#customer-dashboard h2,
#customer-dashboard h3,
#customer-dashboard h4,
#customer-dashboard h5,
#customer-dashboard h6 {
    color: var(--greenD-75);
}

.content-header {
    display: flex;
    justify-content: space-between;
}

#customer-settings {
    gap: var(--unit5);
    overflow-y: auto;
    height: calc(100% - 39px);
    padding: var(--unit4) 0;
}

@media screen and (min-width: 992px) {
    #customer-settings {
        gap: var(--unit6);
    }
}

#customer-billing-info {
    display: flex;
    flex-direction: column;
}

.settings-card-info {
    display: grid;
    gap: var(--unit4);
}

.settings-card {
    padding: var(--unit4);
    background: var(--white);
    border-radius: var(--unit4);
    box-shadow: var(--boxShadowDown);
}

.credit-card {
    background: var(--purple4);
    border-radius: var(--unit4);
    box-shadow: var(--boxShadowDown);
    aspect-ratio: 3.375/2.125;
    padding: var(--unit4);
    font-size: var(--h6);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-height: var(--unit8);
    min-height: calc(var(--unit7) + var(--unit5));
}

.cc-date {
    display: flex;
    flex-direction: column;
}

.cc-name {
    display: flex;
    flex-direction: column;
}

.cc-number {
    display: flex;
    flex-direction: column;
}

.cc-logo {
    display: flex;
    flex-direction: column;
}
