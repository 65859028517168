.map {
    height: 400px;
    border-radius: 5px;
}

.gmnoprint {
    display: none;
}

.dashboard-header {
    grid-area: dashboard-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--greenD-10);
}

.app-root main:has(.dashboard2) {
    margin-top: 0;
    background: var(--purpleD-10);
}

.dashboard2 {
    display: grid;
    grid-template-columns: min-content auto;
    grid-template-rows: auto var(--unit6);
    min-height: 100vh;
    grid-template-areas: 'sidebar main';
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    position: sticky;
    top: 0;
}

.dashboard2 button.nav-toggle {
    position: absolute;
    padding: var(--unit4);
    left: 100%;
    bottom: var(--unit6);
    transform: translateX(-50%);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: white;
}

@media screen and (min-width: 768px) {
    .dashboard2 button.nav-toggle {
        display: flex;
    }
}

.dashboard2 aside {
    --sidebar-padding-amount: var(--unit3);
    grid-area: sidebar;
    position: sticky;
    top: 54.5px;
    max-width: 20ch;
    max-height: calc(100vh - 54.5px);
    display: flex;
    flex-direction: column;
    color: var(--greenD-75);
    bottom: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid var(--greenD-10);
}

.dashboard2 .sidebar-content {
    overflow-y: auto;
    min-height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.dashboard2 main {
    margin-top: 0 !important;
    grid-area: main;
    padding: var(--unit4);
    position: sticky;
    top: 54.5px;
    height: calc(100vh - 54.5px);
    overflow-y: auto;
    color: var(--greenD-75);
}

@media screen and (min-width: 768px) {
    .dashboard2 main {
        padding: var(--unit5);
    }
}

.dashboard2 .sidenav ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.dashboard2 .sidenav li {
    list-style: none !important;
    padding: var(--unit3);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--unit3);
    display: flex;
}

@media screen and (min-width: 480px) {
    .dashboard2 .sidenav li {
        padding: var(--unit3) var(--unit4);
    }
}

.dashboard2 .sidenav.closed li {
    display: flex;
    justify-content: center;
    padding: var(--unit3);
}

.dashboard2 .sidenav li:hover {
    background: var(--purpleD-10);
    cursor: pointer;
    transition: none;
}

@media screen and (min-width: 768px) {
    .dashboard2 .sidenav li {
        justify-content: flex-start;
    }
}

.dashboard2 aside .link-title {
    display: none;
    white-space: nowrap;
    text-transform: capitalize;
}

@media screen and (min-width: 768px) {
    .dashboard2 aside .link-title {
        display: flex;
    }
}

.dashboard2 .sidenav.closed .link-title {
    display: none;
}

.dashboard2 .sidebar-header {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: var(--unit4);
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 480px) {
    .dashboard2 .sidebar-header {
        padding: var(--unit3);
    }
}

@media screen and (min-width: 768px) {
    .dashboard2 aside.sidebar {
        --sidebar-padding-amount: var(--unit4);
    }
}

.dashboard2 .user-name,
.dashboard2 .sidenav.closed .user-name {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    width: unset;
    height: unset;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: var(--unit3);
}

.dashboard2 .sidenav.open .user-name {
    -webkit-writing-mode: normal;
    writing-mode: normal;
}

.dashboard2 .avatar {
    width: calc(var(--unit4) + var(--unit5));
    aspect-ratio: 1/1;
    border-radius: 0;
    background: var(--color-primary);
    background: var(--purple4);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 480px) {
    .dashboard2 .avatar {
        border-radius: 50%;
    }
}

@media screen and (min-width: 768px) {
    .dashboard2 .user-name {
        -webkit-writing-mode: horizontal-tb;
        writing-mode: horizontal-tb;
        transform: scale(1, 1);
        padding: 0 var(--unit4);
    }
}

.dashboard2 .sidebar-links {
    display: flex;
    flex-direction: column;
    gap: var(--unit4);
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    align-items: stretch;
    padding: var(--unit4) 0;
}

.dashboard2-container .tab-link-text {
    font-size: var(--h7);
    display: none;
}

@media screen and (min-width: 480px) {
    .dashboard2-container .tab-link-text {
        display: flex;
    }
}

@media screen and (min-width: 576px) {
    .dashboard2-container .tab-link-text {
        font-size: var(--h6);
    }
}

.nav-link {
    position: relative;
    border-top-left-radius: var(--unit3);
    border-bottom-left-radius: var(--unit3);
}

.nav-link.active {
    background: var(--purple3-50);
}

.nav-link.active::after {
    background-color: var(--purpleB);
    position: absolute;
    right: 0;
    top: 50%;
    height: 80%;
    transform: translateY(-50%);
    width: var(--unit2);
    border-bottom-left-radius: var(--unit2);
    border-top-left-radius: var(--unit2);
    content: '';
}

.dashboard2-container h1,
.dashboard2-container h2,
.dashboard2-container h3,
.dashboard2-container h4,
.dashboard2-container h5,
.dashboard2-container h6 {
    color: var(--greenD-75);
}

.dashboard2-container ul.header-nav {
    display: flex;
    padding: 0;
    margin: 0;
    margin-right: var(--unit3);
    list-style: none;
    font-size: var(--h6);
    color: var(--purpleD-75);
    height: 100%;
}

.dashboard2-container ul.header-nav li {
    display: flex;
    position: relative;
    padding: var(--unit4);
    transition: 0.3s;
}

.dashboard2-container ul.header-nav li:hover {
    cursor: pointer;
    background: var(--purpleD-10);
    transition: none;
}

.dashboard2-container ul.header-nav li.active {
    background: var(--purple3-50);
}

.dashboard2-container ul.header-nav li.active::after {
    width: 100%;
    height: 5px;
    position: absolute;
    top: calc(100% - 2.5px);
    left: 0;
    background: var(--purpleB);
    content: '';
    border-radius: 2.5px;
}

.dashboard2-container ul.header-nav li .header-link-text {
    display: none;
}

@media screen and (min-width: 768px) {
    .dashboard2-container ul.header-nav li .header-link-text {
        display: flex;
    }
}

.content-header-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--purpleD-10);
    margin-bottom: var(--unit4);
    padding-bottom: var(--unit4);
    gap: var(--unit4) var(--unit5);
    flex-wrap: wrap;
}

.content-actions {
    display: flex;
    gap: var(--unit4) var(--unit5);
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    background: red;
}

.orders-status-toggle {
    display: flex;
    align-items: center;
    gap: var(--unit3);
    justify-self: flex-end;
    margin-left: auto;
}

.orders-search {
    display: flex;
    flex: 1;
    min-width: var(--unit8);
}

.quotes-toolbar {
    margin-bottom: var(--unit4);
}

.quote-templates-section-header,
.item-rules-content-header {
    display: flex;
    gap: var(--unit5);
    flex-direction: column;
    margin-top: var(--unit6);
    justify-content: space-between;
}

.add-template-button-container {
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 626px) and (max-width: 767px) {
    .content-header {
        order: -1;
    }

    .orders-button {
        order: 1;
    }

    .orders-search {
        order: -1;
    }
}

@media screen and (min-width: 778px) {
    .content-header {
        order: -1;
    }

    .orders-button {
        order: 1;
    }

    .orders-search {
        order: -1;
    }
}

.toolbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--unit4);
    flex-wrap: wrap;
}

.toolbar > div {
    width: 100%;
}

@media screen and (max-width: 480px) {
    .toolbar > div > * {
        width: 100%;
    }
}

.toolbar .toggle-button {
    width: 90px;
}

@media screen and (min-width: 768px) {
    .toolbar {
        flex-direction: row;
        align-items: center;
        /* justify-content: space-between; */
    }

    .toolbar > div {
        width: fit-content;
        display: flex;
        gap: var(--unit3);
    }

    .quote-templates-section-header,
    .item-rules-content-header {
        flex-direction: row;
    }
}

select {
    padding: var(--unit3) var(--unit5) var(--unit3) var(--unit4);
    border-radius: var(--unit5);
    border-bottom: none;
    box-shadow: 0 6px 12px var(--purpleD-10);
    background-color: white;
    color: var(--purpleD-75);
}

.map-container {
    position: relative;
    border-radius: var(--unit4);
    overflow: hidden;
    box-shadow: 0 6px 12px var(--purpleD-10);
    margin-top: var(--unit4);
}
.order-headlines {
    display: flex;
    gap: var(--unit4);
    position: absolute;
    z-index: 1;
    top: var(--unit4);
    left: var(--unit4);
    flex-direction: column;
}

.headline-card {
    background: white;
    border-radius: var(--unit3);
    padding: var(--unit3) var(--unit4);
    box-shadow: 0 6px 12px var(--purpleD-10);
}

.yard-assessment {
    color: var(--blue1);
}

.installation,
.revive,
.new-garden {
    color: orange;
}

.full-plan {
    color: var(--greenA);
}

.crop-rotation {
    color: slateblue;
}

.misc {
    color: grey;
}

.initial-planting {
    color: goldenrod;
}

.soil-order {
    color: magenta;
}

.yard-assessment::before,
.installation::before,
.revive::before,
.new-garden::before,
.full-plan::before,
.crop-rotation::before,
.misc::before,
.initial-planting::before {
    background: currentColor;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
}

html:has(.dashboard2-container) {
    overflow: hidden;
}
