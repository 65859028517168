.linear-saving-indicator {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f3f3f3;
    height: 10px; /* Adjust the height as needed */
    z-index: 1000; /* Ensure it stays on top of other elements */
}

.progress-bar {
    height: 100%;
    background-color: var(--purpleB);
    transition: width 0.1s ease-in-out;
}
