/* reschedule.css */

#reschedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: var(--unit9);  
    margin: 0 auto;
    padding-top: var(--unit5);
}

.harvest-img {
    width: 100%;
    max-width: var(--unit9);
    margin: 0 auto var(--unit5) auto;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.reschedule-button-container {
    position: relative
}