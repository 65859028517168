/* garden-beds.css */

#products-and-services {
  padding: var(--unit5) var(--unit4);
  display: grid;
  grid-gap: var(--unit6);
}

@media screen and (min-width: 1366px) {
  #products-and-services {
    padding: var(--unit6) 0;
  }
}

/* #products-and-services section {
    max-width: var(--unit10);
} */

#products-and-services .hero-section, #assessments-section {
  gap: var(--unit5);
  display: grid;
}

#products-and-services .hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.size-img {
    margin-block: -15%;
    mix-blend-mode: multiply;
    width: 100%;
}

#sizes-section {
    display: grid;
    grid-gap: var(--unit6);
}

.pricing-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pricing-row > div {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.size-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile-section-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.mobile-section-img img {
  width: 100%;
  max-width: 240px;
  height: auto;
}

.mobile-section-list {
  display: grid;
  grid-gap: var(--unit5);
  margin-top: var(--unit6);
}

section {
  max-width: 1200px;
  margin: 0 auto;
}