/* tabs.css */

.tabs {
    display: flex;
    align-items: center;
    padding: 0;
    /* font-family: "pragmatica", helvetica, arial, sans-serif; */
    font-size: var(--h7);
    margin: 0;
}

.tab-active {
    padding: var(--unit3);
    font-size: var(--h6);
    color: var(--purpleD-75);
    height: 100%;
    background: var(--purple3-50);
    border-radius: var(--unit3);
    cursor: pointer;
}

.tabs .tab.active {
    background: var(--purple4);
}

.tabs-outlined {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: "pragmatica", helvetica, arial, sans-serif;
    font-size: var(--h7);
}

.tabs-outlined .tab {
    padding: var(--unit2) var(--unit3);
    border-left: 1px solid var(--greenD-10);
    border-top: 1px solid var(--greenD-10);
    border-bottom: 1px solid var(--greenD-10);
    color: var(--purpleB);
}

.tabs-outlined .tab:first-child {
    border-radius: var(--unit3) 0 0 var(--unit3);
    border-left: 1px solid var(--greenD-10);
    border-top: 1px solid var(--greenD-10);
    border-bottom: 1px solid var(--greenD-10);
}

.tabs-outlined .tab:last-child {
     border-radius: 0 var(--unit3) var(--unit3) 0;
     border-right: 1px solid var(--greenD-10);

}

.tabs-outlined .tab.active {
    background: var(--purple4);
}

.tab {
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    padding: var(--unit2) var(--unit3);
    color: var(--purpleB);
}

.tab-link-text {
    font-size: var(--h7);
}

@media screen and (min-width: 480px) {
    .tab-link-text {
        display: flex;
    }
}

@media screen and (min-width: 576px) {
    .tab {
        flex-direction: row;
        gap: var(--unit3);
    }

    .tab-link-text {
        font-size: var(--h6);
    }
}

.tab {
    padding: var(--unit3);
    font-size: var(--h6);
    color: var(--purpleD-75);
    height: 100%;
    border-radius: var(--unit3);
    cursor: pointer;
}