/* apply.css */

#application-form .role-description {
    margin-left: calc(var(--unit5) + var(--unit4) + 4px);
}

#application-form .radio-header {
    display: flex;
    gap: var(--unit2);
    flex-direction: column;
}

#application-form .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

input.filepicker[type="file"] {
    width: 100%;
    white-space: nowrap;
    padding: var(--unit3) var(--unit4);
    text-align: center;
    display: flex;
    display: block;
    display: flex;
    justify-content: center;
}

input::file-selector-button {
    font-family: var(--default-font);
    border-radius: var(--unit5);
    padding: var(--unit3) var(--unit4);
    color: var(--purpleB);
    font-weight: bold;
    border: 1px solid white;
    border-color: var(--white-50);
    border-top-color: var(--white);
    border-bottom-color: var(--greenC-10);
    margin-right: var(--unit4);
}

input.file-indicator {
    background: var(--purpleD-10);
    padding: var(--unit1) var(--unit2) !important;
    border: 2px dashed var(--purpleD-25);
    text-align: center;
    border-radius: var(--unit3);
    margin-top: var(--unit3);
    display: flex;
    justify-content: space-between;
}

.name-inputs-container,
.address-inputs-container {
    display: flex;
    gap: var(--unit4);
    flex-wrap: wrap;
}

.name-inputs-container >div:has(input),
.street-address-inputs-container >div:has(input) {
    width: 100%;
    flex-grow: 1;
    min-width: var(--unit7);
}



.application-modal-checkbox-item {
    display: flex;
    align-items: center;
    gap: var(--unit3);
}

.city-state-zip-inputs {
    display: flex;
    gap: var(--unit4);
    flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
    .name-inputs-container {
        flex-wrap: unset;
    }

    .city-state-zip-inputs {
        flex-wrap: unset;
    }
}

.application-modal {
    position: fixed;
    z-index: 1;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    padding: var(--unit4);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: none;
    justify-content: center;
    align-items: center;
}

.application-modal.open {
    display: flex;
}

.application-modal-content {
    background: white;
    padding: var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
    border-radius: var(--unit4);
    overflow-y: auto;
    max-height: calc(100vh - var(--unit5));
    display: flex;
    flex-direction: column;
    max-width: var(--unit9);
}