/* hero section layout grid */
section.hero {
    padding: 0 var(--unit4);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content auto;
    grid-template-areas:
        'hero-headline'
        'hero-cta'
        'carousel';
    max-width: 1366px;
    margin: var(--unit5) auto 0;
    min-height: calc(100vh - 88.16px);
}

@media screen and (min-width: 768px) {
    section.hero {
        margin-top: var(--unit5);
        padding: 0 var(--unit6);
        grid-template-columns: min-content 10fr 1fr 10fr;
        grid-template-areas:
            'hero-left-panel hero-headline hero-headline hero-headline'
            'hero-left-panel hero-cta . hero-img'
            'hero-left-panel carousel . hero-img';
    }
}

@media screen and (min-width: 992px) {
    section.hero {
        margin-top: 0;
        grid-template-columns: min-content 8fr 2fr 12fr;
        padding: var(--unit5);
        grid-template-rows: min-content 1fr auto;
    }
}

@media screen and (min-width: 1024px) {
    section.hero {
        grid-template-columns: min-content 8fr 1fr 13fr;
    }
}

@media screen and (min-width: 1366px) {
    section.hero {
        margin-top: 0;
    }
}

/* headline cell */
.headline {
    grid-area: hero-headline;
    max-width: 15ch;
    font-size: clamp(var(--h2), 10vw, var(--h1));
    color: var(--greenD);
}

@media screen and (min-width: 768px) {
    .headline {
        font-size: var(--h1);
    }
}

@media screen and (min-width: 992px) {
    .headline {
        font-size: clamp(var(--h1), 6vw, calc(var(--unit6) + var(--unit5)));
    }
}

/* cta cell */
.hero-cta {
    grid-area: hero-cta;
    width: fit-content;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .hero-cta {
        padding: var(--unit3) var(--unit4);
    }
}

/* carousel cell */
.carousel-container {
    grid-area: carousel;
    margin: var(--unit4) 0 0 0;
}

@media screen and (min-width: 768px) {
    .carousel-container {
        margin: var(--unit5) 0 0 0;
    }
}

@media screen and (min-width: 992px) {
    .carousel-container {
        margin: var(--unit6) 0 0 0;
    }
}

@media screen and (min-width: 1366px) {
    .carousel-container {
        margin: var(--unit5) 0 0 0;
    }
}

/* garden bed cell */
.hero-img-container {
    grid-area: hero-img;
    position: relative;
    display: none;
}

@media screen and (min-width: 768px) {
    .hero-img-container {
        display: grid;
    }
}

/* left panel cell */
.hero-left-panel {
    grid-area: hero-left-panel;
    list-style: none !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--unit6) var(--unit5) 0;
    display: none;
    height: 100%;
    position: relative;
    font-weight: 500;
}

@media screen and (min-width: 992px) {
    .hero-left-panel {
        display: flex;
    }
}

/* inner elements */
.left-panel-nav {
    display: flex;
    gap: var(--unit5);
    flex-direction: column;
    color: var(--purpleB);
    padding: 0;
    align-items: center;
    margin: 0;
}

.left-panel-nav li {
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    list-style: none !important;
}

.left-panel-nav li a {
    color: var(--purpleB);
    text-decoration: none;
}

.left-panel-social {
    list-style: none !important;
    padding: 0;
    display: flex;
    gap: var(--unit5);
    flex-direction: column;
    margin: 0;
}

.left-panel-social li,
.left-panel-social li a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-panel-social li a:hover {
    transform: scale(1.1);
}

.hero-img {
    width: 200%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(0%, -40%);
}

@media screen and (min-width: 768px) {
    .hero-img {
        width: 150%;
        transform: translate(0%, -10%);
    }
}

@media screen and (min-width: 992px) {
    .hero-img {
        width: 140%;
        transform: translate(0%, -30%);
    }
}

@media screen and (min-width: 1024px) {
    .hero-img {
        width: 130%;
        transform: translate(0%, -20%);
    }
}

@media screen and (min-width: 1366px) {
    .hero-img {
        transform: translate(0%, -30%);
    }
}

.slide-img {
    width: 100%;
    aspect-ratio: 2.4/1;
    object-fit: cover;
    border-radius: var(--unit5);
    margin-top: var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
}

.hero-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
        'qr-code qr-code'
        'established san-francisco';
    bottom: 0;
    gap: var(--unit4);
    position: absolute;
    align-items: end;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
}

@media screen and (min-width: 1024px) {
    .hero-info {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 'established san-francisco qr-code';
    }
}

.qr-code {
    grid-area: qr-code;
    padding: 0 var(--unit6);
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .qr-code {
        padding: 0;
    }
}

.established {
    grid-area: established;
    text-align: center;
}

.san-francisco {
    grid-area: san-francisco;
    text-align: center;
}

.slide-indicators {
    display: flex;
    justify-content: center;
    gap: var(--unit2);
    padding-bottom: var(--unit4);
    border-bottom: 1px solid var(--purple5);
}
.slide-indicator {
    height: 6px;
    border: 1px solid var(--purpleB);
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.slide-indicator.active {
    background-color: var(--purpleB);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 1s;
}

.carousel-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-50%);
    padding-inline: var(--unit4);
}

.slide-controls {
    display: flex;
    gap: var(--unit2);
    align-items: center;
}

.slide-controls button {
    border: none;
    box-shadow: 0 6px 12px var(--greenD-10);
    aspect-ratio: 1/1;
    min-width: unset;
}
