/* customer-order-details.css */

.customer-order-details-container {
    height: calc(100vh - 117px);
    height: calc(100dvh - 117px);
    overflow-y: auto;
    padding: var(--unit5) 0;
}
.customer-order-details {
    background: white;
    padding: var(--unit5);
    border-radius: var(--unit4);
    overflow-y: auto;
    box-shadow: 0 6px 12px var(--greenD-10);
    display: flex;
    flex-direction: column;
    gap: var(--unit4);
}

.order-data {
    display: flex;
    flex-direction: column;
    font-size: var(--h6);
}

.order-images-container {
    display: flex;
    gap: var(--unit4);
    flex-wrap: wrap;
}

.order-images-container .thumbnail {
    object-fit: cover;
    aspect-ratio: 1/1;
    height: var(--unit7);
    box-shadow: 0 6px 12px var(--greenD-10);
    border-radius: var(--unit3);
}

.order-images-container img:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.order-description {
    max-width: 60ch;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    backdrop-filter: blur(3px);
}

.modal-content {
    padding: var(--unit4);
    background: white;
    border-radius: 8px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.modal-content img {
    max-width: 100%;
    max-height: calc(80vh - var(--unit5));
    display: block;
    object-fit: contain;
    margin: 0 auto;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
