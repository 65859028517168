/* maintenance-report.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.badge {
    position: relative;
    left: 145px;
    top: -10px;
    color: white;
    width: fit-content;
    height: 40px;
    padding: 5px 13px;
    border-radius: 50%;
}

.bed-rows {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
}

.garden-bed {
    width: 100px;
    height: 100px;
}

.plants-container {
    max-height: 400px;
    border: 1px solid var(--gray2);
}

.harvested-plant-controls {
    background: var(--gray2);
}

.pending-action-indicator {
    background-color: lightblue;
}
