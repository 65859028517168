.footer-container {
    padding: var(--unit5);
    width: 100%;
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: 1fr 1fr;
}

@media(min-width: 768px) {
    .footer-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

.footer-header {
    line-height: 30px;
    text-decoration: none;
}

.footer-link {
    color: var(--brown2);
    line-height: 30px;
    text-decoration: none;
}

.footer-link:hover {
    color: var(--brown2);
}

.copyright {
    padding: var(--unit5);
    display: flex;
    justify-content: center;
}
