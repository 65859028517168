.messenger-container {
    border-right: 1px solid var(--gray2);
    padding-right: 15px;
}

@media(max-width: 600px) {
    .messenger-container {
        border-right: none;
        padding-right: 0px;
    }
}

.conversation-container {
    border: 1px solid var(--gray2);
}

.conversation-container:hover {
    background-color: var(--gray1);
}

.conversation-container-unread {
    border: 1px solid var(--green3);
}

.sender-chat-bubble {
    background-color: var(--purpleB-90);
    color: var(--white);
    max-width: 75%;
}

.receiver-chat-bubble {
    background-color: var(--white);
    max-width: 75%;
}

.chat-container {
    border: 1px solid var(--gray2);
    background-color: var(--gray1);
    height: 200px;
    overflow-y: scroll;
}

@media(max-width: 600px) {
    .chat-container {
        height: 200px;
    }
}

.chat-date {
    font-size: 10px;
}

.new-message {
    padding: 5px 5px;
}

