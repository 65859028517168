.pricing-container {
    padding: 50px 100px 0px 100px;
}

@media screen and (max-width: 480px) {
    .pricing-container {
        padding: 25px 25px;
    }
}

.offerings {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 480px) {
    .offerings {
        display: block;
    }
}

.offering-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.free-trial-badge {
    background: #ddccff;
    padding: 5px 10px;
    border-radius: 50px;
    width: max-content;
}
