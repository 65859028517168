.navbar {
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 10;
    background-color: var(--white);
    border-bottom-color: var(--greenA);
    box-shadow: 0 3px 6px
        hsla(
            var(--hue),
            var(--saturation),
            calc(var(--lightness) - 10%),
            calc(var(--alpha) - 0.85)
        );
}
