/* @import url("https://use.typekit.net/fug0qzw.css"); */

/* Yarden UI Kit styles */

* {
  scroll-behavior: smooth;
}

input, 
select,
textarea,
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth, 
.MuiSelect-select.MuiSelect-select {
  outline: none;
  border: none;
  padding: var(--unit4);
  width: 100%;
  font-family: var(--default-font);
  font-size: var(--h6);
  margin: 0;
  border-radius: var(--unit4);
  border: 1px solid var(--greenD-10);
  background: var(--greenD-10);
  box-shadow: inset 0 6px 12px var(--greenD-10);
}

input:focus, 
textarea:focus,
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth:focus, 
.MuiSelect-select.MuiSelect-select:focus {
  background: var(--green4);
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-disabled.Mui-disabled.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense * {
  padding: 1px 0;
}

button {
  font-size: var(--h5);
  font-family: var(--defaultFont);
}

h1, h2, h3, h4, h5, h6{
  line-height: var(--headerLineHeight);
  display: block;
  margin: 0;
  color: var(--purpleB);
}

h1, p1 {
  font-size: var(--h1);
}

h2, p2 {
  font-size: var(--h2);
}

 h3, p3 {
  font-size: var(--h3);
}

h4, p4 {
  font-size: var(--h4);
}

h5, body, p, p1, p2, p3, p4, p5, small {
  font-size: var(--h5);
}

h6, small, label, small {
  font-size: var(--h6);
}

label, small, p, p1, p2, p3, p4, p5, small, a {
  line-height: var(--paragraphLineHeight);
}

.bg-purpleB a {
  color: var(--white);
}

.bg-purpleB {
  background-color: var(--purpleB);
}

.paddingUnit4 {
  padding: var(--unit4);
}

.paddingUnit5 {
  padding: var(--unit5);
}

/* custom styles */
header .horizontal-logo {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.show-banner {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.hide-banner {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

section.step-section > div > * {
  place-items: center;
  display: grid;
}

#contact-us {
  padding: var(--unit5);
  max-width: 1200px;
  margin: 0 auto var(--unit5) auto;
}

.contact-form {
  max-width: var(--unit9);
  margin: 0 auto var(--unit6) auto;
}

.step1-qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--unit3);
}

.step1-qr-code > div {
  width: min-content;
  height: min-content;
  margin: 0 auto;
  position: relative;
}

.step1-qr-code > div span.top-left-corner,
.step1-qr-code > div span.top-right-corner,
.step1-qr-code > div span.bottom-left-corner,
.step1-qr-code > div span.bottom-right-corner {
  position: absolute;
  width: var(--unit4);
  height: var(--unit4);
}

.step1-qr-code > div span.top-left-corner {
  border-top: 3px solid var(--green0);
  border-left: 3px solid var(--green0);
  top: 0;
  left: 0;
}

.step1-qr-code > div span.top-right-corner {
  border-top: 3px solid var(--green0);
  border-right: 3px solid var(--green0);
  top: 0;
  right: 0;
}

.step1-qr-code > div span.bottom-left-corner {
  border-bottom: 3px solid var(--green0);
  border-left: 3px solid var(--green0);
  bottom: 0;
  left: 0;
}

.step1-qr-code > div span.bottom-right-corner {
  border-bottom: 3px solid var(--green0);
  border-right: 3px solid var(--green0);
  bottom: 0;
  right: 0;
}

.three-steps-section {
  display: grid;
  gap: var(--unit4);
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit4);
}

@media screen and (min-width: 992px) {
  .three-steps-section {
    grid-template-columns: 1fr 1fr 1fr;

  }
}

.homepage-lower-section {
  margin-bottom: var(--unit5);
}

.homepage-lower-section:nth-child(odd) *:has(img) {
  order: -1;
}

@media screen and (min-width: 786px) {
  .homepage-lower-section:nth-child(odd) div:has(img) {
    order: 1;
  }

  .homepage-lower-section {
    margin-bottom: 0;
  }
  
}