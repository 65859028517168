.expand-button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: var(--unit4);
}

.plant-list {
    position: fixed;
    top: 0px;
    left: 0px;
    margin-right: 15px;
    height: 100vh;
    width: 100%;
    background: white;
    padding: 15px;
    z-index: 1000;
}

.plants-container {
    max-height: 400px;
    border: 1px solid var(--gray2);
}
