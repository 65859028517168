label {
  /* font-size: 13px; */
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
}

summary{
  outline: none;
}

button:focus {
  outline: 0;
} 

/* 3rt party styles */
.MuiInputBase-root-254 {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
}

@media(max-width: 400px) {
  .mobile-settings .MuiTableCell-root-279 {
    padding: 0px !important;
  }  
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper, .react-datepicker__input-container input {
  width: 100%;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--green1) !important;
}

.MuiSwitch-track {
  background-color: var(--gray3) !important;
}

.MuiButton-textPrimary {
  color: var(--black) !important;
}
/* 
.date-container {
  border: 1px solid #CCCCCC;
  padding: 5px 10px;
} */

.date-container .MuiInput-underline {
  width: 100%;
}

/* .date-container .MuiInput-underline:before {
  border: none !important;
}

.date-container .MuiInput-underline:after {
  border: none !important;
}

.date-container .MuiInput-underline:hover {
  border: none !important;
} */

.MuiDataGrid-root .MuiDataGrid-main {
  position: initial !important;
}

/* helpers styles */
.full-width {
  width: 100% !important;
}

.invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Separate rule for compatibility, :focus-within is required on modern Firefox and Chrome */
input.invisible:focus + label {
  outline: thin dotted;
}
input.invisible:focus-within + label {
  outline: thin dotted;
}

.center {
  text-align: 'center'
}

.link {
  color: #4C9AFF !important;
}

.link:hover {
  text-decoration: underline !important;
}

.footer-link {
  color: '#F6F2EE' !important;
}

.footer-link:hover {
  text-decoration: underline !important;
}

.chatbot-toggle {
  position: fixed;
  bottom: var(--unit4);
  right: var(--unit4);
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  border-bottom-left-radius: var(--unit4) !important;
}
.chatbot-open-toggle {
  position: fixed;
  top: calc(61.38px + var(--unit5));
  right: var(--unit5);
  z-index: 1;
}
.unread-badge {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  height: calc(var(--unit4) + var(--unit3));
  background-color: red;
  color: var(--white);
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  transform-origin: bottom center;
  animation: newMessageAnimation 0.4s ease-in-out;
}

@keyframes newMessageAnimation {
  0% {
    opacity: 0;
    transform: scale(0) translateY(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1) translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
