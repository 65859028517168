.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    backdrop-filter: blur(3px);
}

.modal-content {
    padding: var(--unit4);
    background: white;
    border-radius: 8px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.modal-content img {
    max-width: 100%;
    max-height: calc(80vh - var(--unit5));
    display: block;
    object-fit: contain;
    margin: 0 auto;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
