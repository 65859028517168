.tos-container {
    height: 200px;
    overflow-x: scroll;
}

.approve-page-progress-line {
    height: 2px;
}

.signature {
    font-size: 30px;
}

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}