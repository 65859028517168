.section1-container {
    padding: 75px 0px 75px 0px;
}

.section1-cta {
    font-size: 100px !important;
    margin: 0px;
}

.section2-container {
    padding: 75px 0px 75px 0px;
}

.section3-container {
    padding: 100px 0px 100px 0px;
    background-image: url("https://s3-us-west-1.amazonaws.com/yarden-garden/static+assets/company-shirt.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 40vh;
    padding-top: 50px;
    text-align: center;
    margin-bottom: 60vh;
}

.section5-container {
    padding: 100px 0px 100px 0px;
}