/* customer-quotes.css */

#customer-quotes {
    padding: var(--unit4) 0 var(--unit4) 0;
    max-height: calc(100vh - 129px);
    padding-bottom: var(--unit5);
    overflow-y: auto;
}

.bids-container {
    display: flex;
    flex-direction: column;
    gap: var(--unit4);
    background: var(--greenD-10);
    padding: var(--unit4);
    max-height: calc(100vh - 322px);
    border-radius: var(--unit4);
    box-shadow: inset 0 6px 12px var(--greenD-10);
    overflow-y: auto;
}

@media screen and (min-width: 768px) {
    #customer-quotes {
        overflow-y: unset;
    }

    .bids-container {
        max-height: calc(100vh - 174px);
    }
}

.column-empty {
    display: flex;
    justify-content: center;
    background-color: var(--greenD-10);
    align-items: center;
    height: var(--unit7);
    border: 1px solid var(--greenD-25);
    border-radius: var(--unit3);
    opacity: 50%;
}

.bid-card {
    background: white;
    border-radius: var(--unit3);
    padding: var(--unit4) var(--unit4) var(--unit5) var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
    gap: var(--unit4);
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.quote-details-table tbody tr td:not(:first-of-type),
.quote-details-table thead tr th:not(:first-of-type),
.quote-details-table tfoot tr th:not(:first-of-type),
.quote-details-table tfoot tr td:not(:first-of-type) {
    text-align: right;
}

@media screen and (min-width: 992px) {
    .quote-details-table thead tr th:first-child,
    .quote-details-table tbody tr td:first-child,
    .quote-details-table tfoot tr td:first-child,
    .quote-details-table tfoot tr th:first-child {
        width: 25%;
    }

    .quote-details-table tbody tr td:not(:first-of-type),
    .quote-details-table thead tr th:not(:first-of-type),
    .quote-details-table tfoot tr th:not(:first-of-type),
    .quote-details-table tfoot tr td:not(:first-of-type) {
        width: 25%;
    }
}

.customer-bid-details table {
    border: 1px solid var(--greenD-10);
}

.customer-bid-details thead {
    box-shadow: 0 6px 12px var(--greenD-10);
}

.customer-bid-details {
    padding: var(--unit4);
    overflow-y: auto;
    background: white;
    display: flex;
    gap: var(--unit4);
    flex-direction: column;
    box-shadow: 0 6px 12px var(--greenD-10);
    border-radius: var(--unit5);
}

@media screen and (min-width: 992px) {
    .customer-bid-details {
        padding: var(--unit5);
    }
}

.attachment-thumbnail {
    aspect-ratio: 1/1;
    border-radius: var(--unit4);
    height: var(--unit7);
    border: 1px solid white;
    box-shadow: 0 6px 12px var(--greenD-10);
    cursor: pointer;
}

.attachment-thumbnail:hover {
    transform: scale(1.01);
}

.total-container {
    border: 1px solid var(--greenD-10);
    padding: var(--unit3) var(--unit4);
}
