/* plant-selection.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.plant-selection-grid {
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.limit-message {
    gap: var(--unit3);
    flex-wrap: wrap;
    margin-bottom: var(--unit4);
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 640px) {
    .limit-message {
        margin-bottom: var(--unit3);
    }
}
.selection-indicator {
    flex-grow: 1;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
