.reviews-section {
    margin-top: 100px;
}

.reviews-container {
    min-height: 500px;
    gap: 15px;
}

@media screen and (min-width: 768px) {
    .reviews-container {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .review {
        margin-bottom: 25px;
    }
}
