/* General styles for the sections */
.section {
    padding: 10px;
    box-sizing: border-box;
}

/* Style for the container and rows */
.gallery-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px; /* Adjust as needed */
}

.row {
    display: flex;
    flex-wrap: wrap;
}

/* Style for screens larger than 960px */
@media (min-width: 960px) {
    .section {
        width: 33.33%;
    }
}

/* Style for screens smaller than 960px */
@media (max-width: 959px) {
    .section {
        width: 100%;
    }
}

.img-container {
    position: relative;
    width: 100%;
    padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3) */
    overflow: hidden;
}

.img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.modal {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
}

.modal-content img {
    max-width: 100%;
    display: block;
    object-fit: contain;
    width: auto;
    height: auto;
}

.close {
    position: absolute;
    top: 15px;
    right: 30px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.controls button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.controls button:hover {
    background-color: #555;
}
