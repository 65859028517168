@import url(https://use.typekit.net/fug0qzw.css);
/* membership.css */


/* tabs.css */

.tabs {
    display: flex;
    align-items: center;
    padding: 0;
    /* font-family: "pragmatica", helvetica, arial, sans-serif; */
    font-size: var(--h7);
    margin: 0;
}

.tab-active {
    padding: var(--unit3);
    font-size: var(--h6);
    color: var(--purpleD-75);
    height: 100%;
    background: var(--purple3-50);
    border-radius: var(--unit3);
    cursor: pointer;
}

.tabs .tab.active {
    background: var(--purple4);
}

.tabs-outlined {
    display: flex;
    align-items: center;
    padding: 0;
    font-family: "pragmatica", helvetica, arial, sans-serif;
    font-size: var(--h7);
}

.tabs-outlined .tab {
    padding: var(--unit2) var(--unit3);
    border-left: 1px solid var(--greenD-10);
    border-top: 1px solid var(--greenD-10);
    border-bottom: 1px solid var(--greenD-10);
    color: var(--purpleB);
}

.tabs-outlined .tab:first-child {
    border-radius: var(--unit3) 0 0 var(--unit3);
    border-left: 1px solid var(--greenD-10);
    border-top: 1px solid var(--greenD-10);
    border-bottom: 1px solid var(--greenD-10);
}

.tabs-outlined .tab:last-child {
     border-radius: 0 var(--unit3) var(--unit3) 0;
     border-right: 1px solid var(--greenD-10);

}

.tabs-outlined .tab.active {
    background: var(--purple4);
}

.tab {
    display: flex;
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    padding: var(--unit2) var(--unit3);
    color: var(--purpleB);
}

.tab-link-text {
    font-size: var(--h7);
}

@media screen and (min-width: 480px) {
    .tab-link-text {
        display: flex;
    }
}

@media screen and (min-width: 576px) {
    .tab {
        flex-direction: row;
        grid-gap: var(--unit3);
        gap: var(--unit3);
    }

    .tab-link-text {
        font-size: var(--h6);
    }
}

.tab {
    padding: var(--unit3);
    font-size: var(--h6);
    color: var(--purpleD-75);
    height: 100%;
    border-radius: var(--unit3);
    cursor: pointer;
}
/* sidenav.css */


/* customer-order-details.css */

.customer-order-details-container {
    height: calc(100vh - 117px);
    height: calc(100dvh - 117px);
    overflow-y: auto;
    padding: var(--unit5) 0;
}
.customer-order-details {
    background: white;
    padding: var(--unit5);
    border-radius: var(--unit4);
    overflow-y: auto;
    box-shadow: 0 6px 12px var(--greenD-10);
    display: flex;
    flex-direction: column;
    grid-gap: var(--unit4);
    gap: var(--unit4);
}

.order-data {
    display: flex;
    flex-direction: column;
    font-size: var(--h6);
}

.order-images-container {
    display: flex;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    flex-wrap: wrap;
}

.order-images-container .thumbnail {
    object-fit: cover;
    aspect-ratio: 1/1;
    height: var(--unit7);
    box-shadow: 0 6px 12px var(--greenD-10);
    border-radius: var(--unit3);
}

.order-images-container img:hover {
    transform: scale(1.02);
    cursor: pointer;
}

.order-description {
    max-width: 60ch;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

.modal-content {
    padding: var(--unit4);
    background: white;
    border-radius: 8px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.modal-content img {
    max-width: 100%;
    max-height: calc(80vh - var(--unit5));
    display: block;
    object-fit: contain;
    margin: 0 auto;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
}

.modal-content {
    padding: var(--unit4);
    background: white;
    border-radius: 8px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
}

.modal-content img {
    max-width: 100%;
    max-height: calc(80vh - var(--unit5));
    display: block;
    object-fit: contain;
    margin: 0 auto;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/* customer-plant-select.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.plant-selection-grid {
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--unit7) + var(--unit6)), 1fr)
    );
}

.plant-selection-grid .card {
    transition: 0.3s;
    outline: 0 solid transparent;
    background: transparent;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    box-shadow: none;
    outline: 1px solid var(--greenD-10);
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    padding: var(--unit4);
    cursor: pointer;
}

.plant-selection-grid .card:hover {
    outline: 1px solid var(--purple2);
    transition: none;
    transform: scale(1.025);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    box-shadow: 0 6px 12px var(--greenD-10);
}

.plant-selection-grid .card:active {
    transform: scale(1);
    transition: none;
    background: var(--purple5);
}

.plant-selection-grid .card.checked {
    background: white;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    outline: none;
}

.plant-selection-grid .card:has(input[type='checkbox']:checked):hover {
    outline: 1px solid var(--purple2);
}

/* customer-plant-favorites.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.plant-selection-grid {
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--unit7) + var(--unit6)), 1fr)
    );
}

.plant-selection-grid .card {
    transition: 0.3s;
    outline: 0 solid transparent;
    background: transparent;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    box-shadow: none;
    outline: 1px solid var(--greenD-10);
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    padding: var(--unit4);
    cursor: pointer;
}

.plant-selection-grid .card.checked {
    background: white;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    outline: none;
}

.plant-selection-grid .card:hover {
    outline: 1px solid var(--purple2);
    transition: none;
    transform: scale(1.025);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    box-shadow: 0 6px 12px var(--greenD-10);
}

.plant-selection-grid .card:active {
    transform: scale(1);
    transition: none;
    background: var(--purple5);
}

.plant-selection-grid .card:has(input[type='checkbox']:checked):hover {
    outline: 1px solid var(--purple2);
}

.linear-saving-indicator {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f3f3f3;
    height: 10px; /* Adjust the height as needed */
    z-index: 1000; /* Ensure it stays on top of other elements */
}

.progress-bar {
    height: 100%;
    background-color: var(--purpleB);
    transition: width 0.1s ease-in-out;
}

.calendar {
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.weekdays,
.weekdays-mobile,
.days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
    gap: 5px;
}

.weekdays div,
.weekdays-mobile div,
.days div {
    text-align: center;
    padding: 10px;
    border-radius: 4px;
}

.weekdays div,
.weekdays-mobile div {
    font-weight: bold;
    background-color: #f0f0f0;
}

.days .day {
    background-color: #fff;
}

.days .day.harvest {
    background-color: #d4edda;
    color: #155724;
}

@media screen and (max-width: 480px) {
    .weekdays {
        display: none;
    }
}

@media screen and (min-width: 481px) {
    .weekdays-mobile {
        display: none;
    }
}

/* account.css */

html:has(#customer-dashboard) {
    overflow: hidden;
    color: var(--greenD-75);
}

#customer-dashboard {
    display: grid;
    grid-template-areas:
        'customer-header'
        'customer-content';
    grid-template-columns: 1fr;
    background-color: var(--purpleD-10);
    min-height: 100vh;
    min-height: 100dvh;
}

#customer-dashboard-header {
    grid-area: customer-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--greenD-10);
}

#customer-dashboard-content {
    grid-area: customer-content;
    display: grid;
    grid-template-columns: -webkit-min-content auto;
    grid-template-columns: min-content auto;
    grid-template-areas: 'customer-sidebar customer-main';
    width: 100%;
    padding: 0;
}

#customer-dashboard-main {
    grid-area: customer-main;
    padding: var(--unit3);
    height: calc(100vh - 54px);
    height: calc(100dvh - 54px);
    overflow: auto;
    padding-bottom: var(--unit4);
}

@media screen and (min-width: 576px) {
    #customer-dashboard-main {
        padding: var(--unit4);
        /* padding-bottom: var(--unit7); */

    }
}

.customer-dashboard-main-content {
    --header-height: 66px;
    height: calc(100dvh - var(--header-height));
}

#customer-dashboard-sidebar {
    grid-area: customer-sidebar;
    border-right: 1px solid var(--greenD-10);
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 576px) {
    #customer-dashboard-header {
        padding: var(--unit3);
    }

    .customer-dashboard-main-content {
        height: calc(100dvh - var(--header-height) + 7px);
    }
}

@media screen and (min-width: 992px) {
    #customer-dashboard-main {
        padding: var(--unit4) var(--unit4) var(--unit4) var(--unit5);
        /* padding-bottom: var(--unit7); */
    }
}

#sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: var(--unit4) 0;
    grid-gap: var(--unit4);
    gap: var(--unit4);
}

#sidebar-header .user-name {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    width: unset;
    height: unset;
    padding: 0;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

@media screen and (min-width: 768px) {
    #sidebar-header .user-name {
        -webkit-writing-mode: unset;
        writing-mode: unset;
        transform: scale(1);
    }
}

#sidebar-header .avatar {
    width: var(--unit5);
    height: var(--unit5);
    border-radius: 50%;
    background: var(--purpleB);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#customer-dashboard-sidenav {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
}

#customer-dashboard-sidenav li {
    padding: var(--unit3);
    border-top: 1px solid var(--greenD-10);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#customer-dashboard-sidenav li .link-title {
    font-size: var(--h7);
    text-transform: capitalize;
}

#customer-dashboard-sidenav li:hover {
    background: var(--greenD-10);
}

#customer-dashboard-sidenav .sidenav-icon {
    width: var(--h5);
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    #customer-dashboard-sidenav li {
        flex-direction: row;
        grid-gap: var(--unit3);
        gap: var(--unit3);
        justify-content: flex-start;
        padding: var(--unit3) var(--unit4);
    }

    #customer-dashboard-sidenav li .link-title {
        font-size: var(--h6);
    }
}

@media screen and (min-width: 992px) {
    #customer-dashboard-sidenav li {
        flex-direction: row;
        grid-gap: var(--unit4);
        gap: var(--unit4);
        justify-content: flex-start;
        padding: var(--unit3) var(--unit4);
    }

    #customer-dashboard-sidenav li .link-title {
        font-size: var(--h5);
    }
}

#customer-dashboard h1,
#customer-dashboard h2,
#customer-dashboard h3,
#customer-dashboard h4,
#customer-dashboard h5,
#customer-dashboard h6 {
    color: var(--greenD-75);
}

.content-header {
    display: flex;
    justify-content: space-between;
}

#customer-settings {
    grid-gap: var(--unit5);
    gap: var(--unit5);
    overflow-y: auto;
    height: calc(100% - 39px);
    padding: var(--unit4) 0;
}

@media screen and (min-width: 992px) {
    #customer-settings {
        grid-gap: var(--unit6);
        gap: var(--unit6);
    }
}

#customer-billing-info {
    display: flex;
    flex-direction: column;
}

.settings-card-info {
    display: grid;
    grid-gap: var(--unit4);
    gap: var(--unit4);
}

.settings-card {
    padding: var(--unit4);
    background: var(--white);
    border-radius: var(--unit4);
    box-shadow: var(--boxShadowDown);
}

.credit-card {
    background: var(--purple4);
    border-radius: var(--unit4);
    box-shadow: var(--boxShadowDown);
    aspect-ratio: 3.375/2.125;
    padding: var(--unit4);
    font-size: var(--h6);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    max-height: var(--unit8);
    min-height: calc(var(--unit7) + var(--unit5));
}

.cc-date {
    display: flex;
    flex-direction: column;
}

.cc-name {
    display: flex;
    flex-direction: column;
}

.cc-number {
    display: flex;
    flex-direction: column;
}

.cc-logo {
    display: flex;
    flex-direction: column;
}

/* customer-orders.css */

#customer-orders {
    padding: var(--unit4) 0 var(--unit4) 0;
    max-height: calc(100vh - 129px);
    padding-bottom: var(--unit5);
    overflow-y: auto;
}

.orders-container {
    display: flex;
    flex-direction: column;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    background: var(--greenD-10);
    padding: var(--unit4);
    max-height: calc(100vh - 322px);
    border-radius: var(--unit4);
    box-shadow: inset 0 6px 12px var(--greenD-10);
    overflow-y: auto;
}

@media screen and (min-width: 768px) {
    #customer-orders {
        overflow-y: unset;
    }

    .orders-container {
        max-height: calc(100vh - 174px);
    }
}

.column-empty {
    display: flex;
    justify-content: center;
    background-color: var(--greenD-10);
    align-items: center;
    height: var(--unit7);
    border: 1px solid var(--greenD-25);
    border-radius: var(--unit3);
}

.order-count {
    border-radius: var(--unit2);
    display: flex;
    align-items: center;
}

.order-card {
    background: white;
    border-radius: var(--unit3);
    padding: var(--unit4) var(--unit4) var(--unit5) var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
    grid-gap: var(--unit4);
    gap: var(--unit4);
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.order-card-container {
    transition: 0.3s;
    transform: scale(1);
}
.order-card-container:hover {
    transform: scale(1.01);
    transition: none;
}

.order-card-header {
    display: flex;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    flex-wrap: wrap;
    justify-content: space-between;
}

.order-card-header .info {
    display: flex;
    flex-direction: column;
}

.order-card-header .info data {
    font-size: var(--h6);
    height: var(--h5);
    display: flex;
    align-items: center;
    padding-top: var(--unit4);
}

.vendor-info {
    display: flex;
    align-items: center;
    grid-gap: var(--unit3);
    gap: var(--unit3);
}

.order-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    width: 100%;
    overflow: hidden;
}

.order-card .avatar,
.customer-order-details .avatar {
    border-radius: 50%;
    height: var(--unit5);
    aspect-ratio: 1/1;
    object-fit: cover;
    border: 2px solid var(--purpleB);
}

/* customer-quotes.css */

#customer-quotes {
    padding: var(--unit4) 0 var(--unit4) 0;
    max-height: calc(100vh - 129px);
    padding-bottom: var(--unit5);
    overflow-y: auto;
}

.bids-container {
    display: flex;
    flex-direction: column;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    background: var(--greenD-10);
    padding: var(--unit4);
    max-height: calc(100vh - 322px);
    border-radius: var(--unit4);
    box-shadow: inset 0 6px 12px var(--greenD-10);
    overflow-y: auto;
}

@media screen and (min-width: 768px) {
    #customer-quotes {
        overflow-y: unset;
    }

    .bids-container {
        max-height: calc(100vh - 174px);
    }
}

.column-empty {
    display: flex;
    justify-content: center;
    background-color: var(--greenD-10);
    align-items: center;
    height: var(--unit7);
    border: 1px solid var(--greenD-25);
    border-radius: var(--unit3);
    opacity: 50%;
}

.bid-card {
    background: white;
    border-radius: var(--unit3);
    padding: var(--unit4) var(--unit4) var(--unit5) var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
    grid-gap: var(--unit4);
    gap: var(--unit4);
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
}

.quote-details-table tbody tr td:not(:first-of-type),
.quote-details-table thead tr th:not(:first-of-type),
.quote-details-table tfoot tr th:not(:first-of-type),
.quote-details-table tfoot tr td:not(:first-of-type) {
    text-align: right;
}

@media screen and (min-width: 992px) {
    .quote-details-table thead tr th:first-child,
    .quote-details-table tbody tr td:first-child,
    .quote-details-table tfoot tr td:first-child,
    .quote-details-table tfoot tr th:first-child {
        width: 25%;
    }

    .quote-details-table tbody tr td:not(:first-of-type),
    .quote-details-table thead tr th:not(:first-of-type),
    .quote-details-table tfoot tr th:not(:first-of-type),
    .quote-details-table tfoot tr td:not(:first-of-type) {
        width: 25%;
    }
}

.customer-bid-details table {
    border: 1px solid var(--greenD-10);
}

.customer-bid-details thead {
    box-shadow: 0 6px 12px var(--greenD-10);
}

.customer-bid-details {
    padding: var(--unit4);
    overflow-y: auto;
    background: white;
    display: flex;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    flex-direction: column;
    box-shadow: 0 6px 12px var(--greenD-10);
    border-radius: var(--unit5);
}

@media screen and (min-width: 992px) {
    .customer-bid-details {
        padding: var(--unit5);
    }
}

.attachment-thumbnail {
    aspect-ratio: 1/1;
    border-radius: var(--unit4);
    height: var(--unit7);
    border: 1px solid white;
    box-shadow: 0 6px 12px var(--greenD-10);
    cursor: pointer;
}

.attachment-thumbnail:hover {
    transform: scale(1.01);
}

.total-container {
    border: 1px solid var(--greenD-10);
    padding: var(--unit3) var(--unit4);
}

/* google-review-prompt.css */

.google-review-prompt {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: var(--unit4);
    z-index: 2;
    max-width: calc(var(--unit8) + var(--unit7));
    outline: 1px solid var(--greenD-10);
    border-radius: var(--unit4);
}

.google-review-image {
    max-width: 100%;
    margin-top: -60px;
    margin-bottom: -30px;
    pointer-events: none;
}

.valid {
    color: var(--green1)
}
.navbar {
    width: 100%;
    height: 75px;
    position: fixed;
    z-index: 10;
    background-color: var(--white);
    border-bottom-color: var(--greenA);
    box-shadow: 0 3px 6px
        hsla(
            var(--hue),
            var(--saturation),
            calc(var(--lightness) - 10%),
            calc(var(--alpha) - 0.85)
        );
}

/* @import url("https://use.typekit.net/fug0qzw.css"); */

/* Yarden UI Kit styles */

* {
  scroll-behavior: smooth;
}

input, 
select,
textarea,
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth, 
.MuiSelect-select.MuiSelect-select {
  outline: none;
  border: none;
  padding: var(--unit4);
  width: 100%;
  font-family: var(--default-font);
  font-size: var(--h6);
  margin: 0;
  border-radius: var(--unit4);
  border: 1px solid var(--greenD-10);
  background: var(--greenD-10);
  box-shadow: inset 0 6px 12px var(--greenD-10);
}

input:focus, 
textarea:focus,
.MuiFormControl-root.MuiTextField-root.MuiFormControl-marginDense.MuiFormControl-fullWidth:focus, 
.MuiSelect-select.MuiSelect-select:focus {
  background: var(--green4);
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-disabled.Mui-disabled.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-marginDense.MuiInput-marginDense * {
  padding: 1px 0;
}

button {
  font-size: var(--h5);
  font-family: var(--defaultFont);
}

h1, h2, h3, h4, h5, h6{
  line-height: var(--headerLineHeight);
  display: block;
  margin: 0;
  color: var(--purpleB);
}

h1, p1 {
  font-size: var(--h1);
}

h2, p2 {
  font-size: var(--h2);
}

 h3, p3 {
  font-size: var(--h3);
}

h4, p4 {
  font-size: var(--h4);
}

h5, body, p, p1, p2, p3, p4, p5, small {
  font-size: var(--h5);
}

h6, small, label, small {
  font-size: var(--h6);
}

label, small, p, p1, p2, p3, p4, p5, small, a {
  line-height: var(--paragraphLineHeight);
}

.bg-purpleB a {
  color: var(--white);
}

.bg-purpleB {
  background-color: var(--purpleB);
}

.paddingUnit4 {
  padding: var(--unit4);
}

.paddingUnit5 {
  padding: var(--unit5);
}

/* custom styles */
header .horizontal-logo {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.show-banner {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.hide-banner {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

section.step-section > div > * {
  place-items: center;
  display: grid;
}

#contact-us {
  padding: var(--unit5);
  max-width: 1200px;
  margin: 0 auto var(--unit5) auto;
}

.contact-form {
  max-width: var(--unit9);
  margin: 0 auto var(--unit6) auto;
}

.step1-qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--unit3);
}

.step1-qr-code > div {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 0 auto;
  position: relative;
}

.step1-qr-code > div span.top-left-corner,
.step1-qr-code > div span.top-right-corner,
.step1-qr-code > div span.bottom-left-corner,
.step1-qr-code > div span.bottom-right-corner {
  position: absolute;
  width: var(--unit4);
  height: var(--unit4);
}

.step1-qr-code > div span.top-left-corner {
  border-top: 3px solid var(--green0);
  border-left: 3px solid var(--green0);
  top: 0;
  left: 0;
}

.step1-qr-code > div span.top-right-corner {
  border-top: 3px solid var(--green0);
  border-right: 3px solid var(--green0);
  top: 0;
  right: 0;
}

.step1-qr-code > div span.bottom-left-corner {
  border-bottom: 3px solid var(--green0);
  border-left: 3px solid var(--green0);
  bottom: 0;
  left: 0;
}

.step1-qr-code > div span.bottom-right-corner {
  border-bottom: 3px solid var(--green0);
  border-right: 3px solid var(--green0);
  bottom: 0;
  right: 0;
}

.three-steps-section {
  display: grid;
  grid-gap: var(--unit4);
  gap: var(--unit4);
}

.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: var(--unit4);
  gap: var(--unit4);
}

@media screen and (min-width: 992px) {
  .three-steps-section {
    grid-template-columns: 1fr 1fr 1fr;

  }
}

.homepage-lower-section {
  margin-bottom: var(--unit5);
}

.homepage-lower-section:nth-child(odd) *:has(img) {
  order: -1;
}

@media screen and (min-width: 786px) {
  .homepage-lower-section:nth-child(odd) div:has(img) {
    order: 1;
  }

  .homepage-lower-section {
    margin-bottom: 0;
  }
  
}
/* apply.css */

#application-form .role-description {
    margin-left: calc(var(--unit5) + var(--unit4) + 4px);
}

#application-form .radio-header {
    display: flex;
    grid-gap: var(--unit2);
    gap: var(--unit2);
    flex-direction: column;
}

#application-form .button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

input.filepicker[type="file"] {
    width: 100%;
    white-space: nowrap;
    padding: var(--unit3) var(--unit4);
    text-align: center;
    display: flex;
    display: block;
    display: flex;
    justify-content: center;
}

input::file-selector-button {
    font-family: var(--default-font);
    border-radius: var(--unit5);
    padding: var(--unit3) var(--unit4);
    color: var(--purpleB);
    font-weight: bold;
    border: 1px solid white;
    border-color: var(--white-50);
    border-top-color: var(--white);
    border-bottom-color: var(--greenC-10);
    margin-right: var(--unit4);
}

input.file-indicator {
    background: var(--purpleD-10);
    padding: var(--unit1) var(--unit2) !important;
    border: 2px dashed var(--purpleD-25);
    text-align: center;
    border-radius: var(--unit3);
    margin-top: var(--unit3);
    display: flex;
    justify-content: space-between;
}

.name-inputs-container,
.address-inputs-container {
    display: flex;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    flex-wrap: wrap;
}

.name-inputs-container >div:has(input),
.street-address-inputs-container >div:has(input) {
    width: 100%;
    flex-grow: 1;
    min-width: var(--unit7);
}



.application-modal-checkbox-item {
    display: flex;
    align-items: center;
    grid-gap: var(--unit3);
    gap: var(--unit3);
}

.city-state-zip-inputs {
    display: flex;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    flex-wrap: wrap;
}

@media screen and (min-width: 768px) {
    .name-inputs-container {
        flex-wrap: unset;
    }

    .city-state-zip-inputs {
        flex-wrap: unset;
    }
}

.application-modal {
    position: fixed;
    z-index: 1;
    background: rgba(0,0,0,0.5);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    padding: var(--unit4);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: none;
    justify-content: center;
    align-items: center;
}

.application-modal.open {
    display: flex;
}

.application-modal-content {
    background: white;
    padding: var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
    border-radius: var(--unit4);
    overflow-y: auto;
    max-height: calc(100vh - var(--unit5));
    display: flex;
    flex-direction: column;
    max-width: var(--unit9);
}
.tos-container {
    height: 200px;
    overflow-x: scroll;
}

.approve-page-progress-line {
    height: 2px;
}

.signature {
    font-size: 30px;
}

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}
@media(max-width: 600px) {
    .approved-section1{
        padding-left: 15px;
        padding-right: 15px;
    }
}
.section1-container {
    padding: 75px 0px 75px 0px;
}

.section1-cta {
    font-size: 100px !important;
    margin: 0px;
}

.section2-container {
    padding: 75px 0px 75px 0px;
}

.section3-container {
    padding: 100px 0px 100px 0px;
    background-image: url("https://s3-us-west-1.amazonaws.com/yarden-garden/static+assets/company-shirt.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 40vh;
    padding-top: 50px;
    text-align: center;
    margin-bottom: 60vh;
}

.section5-container {
    padding: 100px 0px 100px 0px;
}
.messenger-container {
    border-right: 1px solid var(--gray2);
    padding-right: 15px;
}

@media(max-width: 600px) {
    .messenger-container {
        border-right: none;
        padding-right: 0px;
    }
}

.conversation-container {
    border: 1px solid var(--gray2);
}

.conversation-container:hover {
    background-color: var(--gray1);
}

.conversation-container-unread {
    border: 1px solid var(--green3);
}

.sender-chat-bubble {
    background-color: var(--purpleB-90);
    color: var(--white);
    max-width: 75%;
}

.receiver-chat-bubble {
    background-color: var(--white);
    max-width: 75%;
}

.chat-container {
    border: 1px solid var(--gray2);
    background-color: var(--gray1);
    height: 200px;
    overflow-y: scroll;
}

@media(max-width: 600px) {
    .chat-container {
        height: 200px;
    }
}

.chat-date {
    font-size: 10px;
}

.new-message {
    padding: 5px 5px;
}


/* YardenTable.css */

.table-wrapper {
    box-shadow: 0 6px 12px var(--purpleC-10);
    max-height: calc(100vh - var(--unit8));
    overflow-x: auto;
    overflow-y: scroll;
    padding: initial;
    padding: unset;
    position: relative;
    width: 100%;
}

.table-wrapper table {
    border-collapse: collapse;
    border-radius: var(--unit4);
    text-align: left;
    width: 100%;
}

.table-wrapper table tbody td {
    background-color: transparent;
    padding: var(--unit3) var(--unit4);
    text-transform: capitalize;
    white-space: nowrap;
    font-size: var(--h6);
}

.table-wrapper table tbody td:first-of-type,
.table-wrapper table thead th:first-of-type {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}

.table-wrapper table tbody td:first-of-type:after,
.table-wrapper table thead th:first-of-type:after {
    background: var(--purpleD-10);
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;
}

.table-wrapper table tbody td:first-of-type:before,
.table-wrapper table thead th:first-of-type:before {
    background: var(--purpleC-10);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
}

.table-wrapper table tbody tr {
    border-top: 1px solid var(--purpleC-10);
}

.table-wrapper table tbody tr:nth-child(even) {
    background: rgba(255, 255, 255, 0.5);
}

.table-wrapper table tbody tr:nth-child(odd) {
    background: #e6e5ea;
}

.table-wrapper table tbody tr:nth-child(even) td:first-of-type {
    background: #f9f9fa;
}

.table-wrapper table tbody tr:nth-child(odd) td:first-of-type {
    background: #e6e5ea;
}

.table-wrapper table thead {
    background: white;
    cursor: pointer;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-wrapper table thead th {
    border-bottom: 1px solid var(--purpleC-10);
    padding: var(--unit3) var(--unit4);
    white-space: nowrap;
}

.table-wrapper table thead th:first-of-type {
    background: white;
    z-index: 1;
}

.table-wrapper table tfoot {
    border: 2px solid var(--greenD-25);
}

.table-wrapper table tfoot td {
    text-align: right;
    padding: var(--unit4);
}

.table-wrapper table tfoot th {
    border-right: 1px solid var(--greenD-10);
    padding: var(--unit4);
}


/* OrderDetails.css */

.data-detail {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.harvest-indicator {
    background-color: lightblue;
}

.expand-button {
    position: fixed;
    bottom: 15px;
    right: 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding: var(--unit4);
}

.plant-list {
    position: fixed;
    top: 0px;
    left: 0px;
    margin-right: 15px;
    height: 100vh;
    width: 100%;
    background: white;
    padding: 15px;
    z-index: 1000;
}

.plants-container {
    max-height: 400px;
    border: 1px solid var(--gray2);
}

.toggle-button {
  width: var(--unit6);
  height: var(--unit5);
  border-radius: var(--unit5);
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: inset 0 3px 6px var(--greenD-25);
}
.toggle-button.on {
  background-color: var(--greenA);
}
.toggle-button.off {
  background-color: var(--purpleD-25);
}
.toggle-knob {
  width: var(--unit5);
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: var(--unit5);
  position: absolute;
  transform: translateX(0);
  transition: transform 0.3s;
  box-shadow: 0 3px 6px var(--greenD-25);
}
.toggle-button.on .toggle-knob {
  transform: translateX(var(--unit5));
}

.map {
    height: 400px;
    border-radius: 5px;
}

.gmnoprint {
    display: none;
}

.dashboard-header {
    grid-area: dashboard-header;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--greenD-10);
}

.app-root main:has(.dashboard2) {
    margin-top: 0;
    background: var(--purpleD-10);
}

.dashboard2 {
    display: grid;
    grid-template-columns: -webkit-min-content auto;
    grid-template-columns: min-content auto;
    grid-template-rows: auto var(--unit6);
    min-height: 100vh;
    grid-template-areas: 'sidebar main';
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.dashboard2 button.nav-toggle {
    position: absolute;
    padding: var(--unit4);
    left: 100%;
    bottom: var(--unit6);
    transform: translateX(-50%);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background: white;
}

@media screen and (min-width: 768px) {
    .dashboard2 button.nav-toggle {
        display: flex;
    }
}

.dashboard2 aside {
    --sidebar-padding-amount: var(--unit3);
    grid-area: sidebar;
    position: -webkit-sticky;
    position: sticky;
    top: 54.5px;
    max-width: 20ch;
    max-height: calc(100vh - 54.5px);
    display: flex;
    flex-direction: column;
    color: var(--greenD-75);
    bottom: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid var(--greenD-10);
}

.dashboard2 .sidebar-content {
    overflow-y: auto;
    min-height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.dashboard2 main {
    margin-top: 0 !important;
    grid-area: main;
    padding: var(--unit4);
    position: -webkit-sticky;
    position: sticky;
    top: 54.5px;
    height: calc(100vh - 54.5px);
    overflow-y: auto;
    color: var(--greenD-75);
}

@media screen and (min-width: 768px) {
    .dashboard2 main {
        padding: var(--unit5);
    }
}

.dashboard2 .sidenav ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.dashboard2 .sidenav li {
    list-style: none !important;
    padding: var(--unit3);
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: var(--unit3);
    gap: var(--unit3);
    display: flex;
}

@media screen and (min-width: 480px) {
    .dashboard2 .sidenav li {
        padding: var(--unit3) var(--unit4);
    }
}

.dashboard2 .sidenav.closed li {
    display: flex;
    justify-content: center;
    padding: var(--unit3);
}

.dashboard2 .sidenav li:hover {
    background: var(--purpleD-10);
    cursor: pointer;
    transition: none;
}

@media screen and (min-width: 768px) {
    .dashboard2 .sidenav li {
        justify-content: flex-start;
    }
}

.dashboard2 aside .link-title {
    display: none;
    white-space: nowrap;
    text-transform: capitalize;
}

@media screen and (min-width: 768px) {
    .dashboard2 aside .link-title {
        display: flex;
    }
}

.dashboard2 .sidenav.closed .link-title {
    display: none;
}

.dashboard2 .sidebar-header {
    display: flex;
    flex-direction: column;
    padding: 0;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 480px) {
    .dashboard2 .sidebar-header {
        padding: var(--unit3);
    }
}

@media screen and (min-width: 768px) {
    .dashboard2 aside.sidebar {
        --sidebar-padding-amount: var(--unit4);
    }
}

.dashboard2 .user-name,
.dashboard2 .sidenav.closed .user-name {
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: scale(-1, -1);
    width: unset;
    height: unset;
    padding: 0;
    display: flex;
    align-items: center;
    margin-bottom: var(--unit3);
}

.dashboard2 .sidenav.open .user-name {
    -webkit-writing-mode: normal;
    writing-mode: normal;
}

.dashboard2 .avatar {
    width: calc(var(--unit4) + var(--unit5));
    aspect-ratio: 1/1;
    border-radius: 0;
    background: var(--color-primary);
    background: var(--purple4);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 480px) {
    .dashboard2 .avatar {
        border-radius: 50%;
    }
}

@media screen and (min-width: 768px) {
    .dashboard2 .user-name {
        -webkit-writing-mode: horizontal-tb;
        writing-mode: horizontal-tb;
        transform: scale(1, 1);
        padding: 0 var(--unit4);
    }
}

.dashboard2 .sidebar-links {
    display: flex;
    flex-direction: column;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    align-items: stretch;
    padding: var(--unit4) 0;
}

.dashboard2-container .tab-link-text {
    font-size: var(--h7);
    display: none;
}

@media screen and (min-width: 480px) {
    .dashboard2-container .tab-link-text {
        display: flex;
    }
}

@media screen and (min-width: 576px) {
    .dashboard2-container .tab-link-text {
        font-size: var(--h6);
    }
}

.nav-link {
    position: relative;
    border-top-left-radius: var(--unit3);
    border-bottom-left-radius: var(--unit3);
}

.nav-link.active {
    background: var(--purple3-50);
}

.nav-link.active::after {
    background-color: var(--purpleB);
    position: absolute;
    right: 0;
    top: 50%;
    height: 80%;
    transform: translateY(-50%);
    width: var(--unit2);
    border-bottom-left-radius: var(--unit2);
    border-top-left-radius: var(--unit2);
    content: '';
}

.dashboard2-container h1,
.dashboard2-container h2,
.dashboard2-container h3,
.dashboard2-container h4,
.dashboard2-container h5,
.dashboard2-container h6 {
    color: var(--greenD-75);
}

.dashboard2-container ul.header-nav {
    display: flex;
    padding: 0;
    margin: 0;
    margin-right: var(--unit3);
    list-style: none;
    font-size: var(--h6);
    color: var(--purpleD-75);
    height: 100%;
}

.dashboard2-container ul.header-nav li {
    display: flex;
    position: relative;
    padding: var(--unit4);
    transition: 0.3s;
}

.dashboard2-container ul.header-nav li:hover {
    cursor: pointer;
    background: var(--purpleD-10);
    transition: none;
}

.dashboard2-container ul.header-nav li.active {
    background: var(--purple3-50);
}

.dashboard2-container ul.header-nav li.active::after {
    width: 100%;
    height: 5px;
    position: absolute;
    top: calc(100% - 2.5px);
    left: 0;
    background: var(--purpleB);
    content: '';
    border-radius: 2.5px;
}

.dashboard2-container ul.header-nav li .header-link-text {
    display: none;
}

@media screen and (min-width: 768px) {
    .dashboard2-container ul.header-nav li .header-link-text {
        display: flex;
    }
}

.content-header-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--purpleD-10);
    margin-bottom: var(--unit4);
    padding-bottom: var(--unit4);
    grid-gap: var(--unit4) var(--unit5);
    gap: var(--unit4) var(--unit5);
    flex-wrap: wrap;
}

.content-actions {
    display: flex;
    grid-gap: var(--unit4) var(--unit5);
    gap: var(--unit4) var(--unit5);
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    background: red;
}

.orders-status-toggle {
    display: flex;
    align-items: center;
    grid-gap: var(--unit3);
    gap: var(--unit3);
    justify-self: flex-end;
    margin-left: auto;
}

.orders-search {
    display: flex;
    flex: 1 1;
    min-width: var(--unit8);
}

.quotes-toolbar {
    margin-bottom: var(--unit4);
}

.quote-templates-section-header,
.item-rules-content-header {
    display: flex;
    grid-gap: var(--unit5);
    gap: var(--unit5);
    flex-direction: column;
    margin-top: var(--unit6);
    justify-content: space-between;
}

.add-template-button-container {
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 626px) and (max-width: 767px) {
    .content-header {
        order: -1;
    }

    .orders-button {
        order: 1;
    }

    .orders-search {
        order: -1;
    }
}

@media screen and (min-width: 778px) {
    .content-header {
        order: -1;
    }

    .orders-button {
        order: 1;
    }

    .orders-search {
        order: -1;
    }
}

.toolbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    flex-wrap: wrap;
}

.toolbar > div {
    width: 100%;
}

@media screen and (max-width: 480px) {
    .toolbar > div > * {
        width: 100%;
    }
}

.toolbar .toggle-button {
    width: 90px;
}

@media screen and (min-width: 768px) {
    .toolbar {
        flex-direction: row;
        align-items: center;
        /* justify-content: space-between; */
    }

    .toolbar > div {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        display: flex;
        grid-gap: var(--unit3);
        gap: var(--unit3);
    }

    .quote-templates-section-header,
    .item-rules-content-header {
        flex-direction: row;
    }
}

select {
    padding: var(--unit3) var(--unit5) var(--unit3) var(--unit4);
    border-radius: var(--unit5);
    border-bottom: none;
    box-shadow: 0 6px 12px var(--purpleD-10);
    background-color: white;
    color: var(--purpleD-75);
}

.map-container {
    position: relative;
    border-radius: var(--unit4);
    overflow: hidden;
    box-shadow: 0 6px 12px var(--purpleD-10);
    margin-top: var(--unit4);
}
.order-headlines {
    display: flex;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    position: absolute;
    z-index: 1;
    top: var(--unit4);
    left: var(--unit4);
    flex-direction: column;
}

.headline-card {
    background: white;
    border-radius: var(--unit3);
    padding: var(--unit3) var(--unit4);
    box-shadow: 0 6px 12px var(--purpleD-10);
}

.yard-assessment {
    color: var(--blue1);
}

.installation,
.revive,
.new-garden {
    color: orange;
}

.full-plan {
    color: var(--greenA);
}

.crop-rotation {
    color: slateblue;
}

.misc {
    color: grey;
}

.initial-planting {
    color: goldenrod;
}

.soil-order {
    color: magenta;
}

.yard-assessment::before,
.installation::before,
.revive::before,
.new-garden::before,
.full-plan::before,
.crop-rotation::before,
.misc::before,
.initial-planting::before {
    background: currentColor;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
}

html:has(.dashboard2-container) {
    overflow: hidden;
}

/* desktop-nav.css */

.desktop-nav {
    display: none;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: var(--unit6);
    padding-right: var(--unit6);
}

@media screen and (min-width: 992px) {
    .desktop-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--white);
        width: 100%;
    }
}

.desktop-nav-logo-and-stories {
    display: flex;
    align-items: center;
    grid-gap: var(--unit5);
    gap: var(--unit5);
}

.desktop-nav-logo-and-stories a {
    display: flex;
    align-items: center;
}
.desktop-nav-list {
    display: flex;
    align-items: center;
    grid-gap: var(--unit5);
    gap: var(--unit5);
    font-weight: 500;
}

.desktop-nav-list li {
    list-style: none;
    color: var(--purpleB);
}

.desktop-nav-list li a {
    color: var(--purpleB);
    text-decoration: none;
}
.footer-container {
    padding: var(--unit5);
    width: 100%;
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: 1fr 1fr;
}

@media(min-width: 768px) {
    .footer-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

.footer-header {
    line-height: 30px;
    text-decoration: none;
}

.footer-link {
    color: var(--brown2);
    line-height: 30px;
    text-decoration: none;
}

.footer-link:hover {
    color: var(--brown2);
}

.copyright {
    padding: var(--unit5);
    display: flex;
    justify-content: center;
}

/* hero section layout grid */
section.hero {
    padding: 0 var(--unit4);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: -webkit-min-content -webkit-min-content auto;
    grid-template-rows: min-content min-content auto;
    grid-template-areas:
        'hero-headline'
        'hero-cta'
        'carousel';
    max-width: 1366px;
    margin: var(--unit5) auto 0;
    min-height: calc(100vh - 88.16px);
}

@media screen and (min-width: 768px) {
    section.hero {
        margin-top: var(--unit5);
        padding: 0 var(--unit6);
        grid-template-columns: -webkit-min-content 10fr 1fr 10fr;
        grid-template-columns: min-content 10fr 1fr 10fr;
        grid-template-areas:
            'hero-left-panel hero-headline hero-headline hero-headline'
            'hero-left-panel hero-cta . hero-img'
            'hero-left-panel carousel . hero-img';
    }
}

@media screen and (min-width: 992px) {
    section.hero {
        margin-top: 0;
        grid-template-columns: -webkit-min-content 8fr 2fr 12fr;
        grid-template-columns: min-content 8fr 2fr 12fr;
        padding: var(--unit5);
        grid-template-rows: -webkit-min-content 1fr auto;
        grid-template-rows: min-content 1fr auto;
    }
}

@media screen and (min-width: 1024px) {
    section.hero {
        grid-template-columns: -webkit-min-content 8fr 1fr 13fr;
        grid-template-columns: min-content 8fr 1fr 13fr;
    }
}

@media screen and (min-width: 1366px) {
    section.hero {
        margin-top: 0;
    }
}

/* headline cell */
.headline {
    grid-area: hero-headline;
    max-width: 15ch;
    font-size: clamp(var(--h2), 10vw, var(--h1));
    color: var(--greenD);
}

@media screen and (min-width: 768px) {
    .headline {
        font-size: var(--h1);
    }
}

@media screen and (min-width: 992px) {
    .headline {
        font-size: clamp(var(--h1), 6vw, calc(var(--unit6) + var(--unit5)));
    }
}

/* cta cell */
.hero-cta {
    grid-area: hero-cta;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .hero-cta {
        padding: var(--unit3) var(--unit4);
    }
}

/* carousel cell */
.carousel-container {
    grid-area: carousel;
    margin: var(--unit4) 0 0 0;
}

@media screen and (min-width: 768px) {
    .carousel-container {
        margin: var(--unit5) 0 0 0;
    }
}

@media screen and (min-width: 992px) {
    .carousel-container {
        margin: var(--unit6) 0 0 0;
    }
}

@media screen and (min-width: 1366px) {
    .carousel-container {
        margin: var(--unit5) 0 0 0;
    }
}

/* garden bed cell */
.hero-img-container {
    grid-area: hero-img;
    position: relative;
    display: none;
}

@media screen and (min-width: 768px) {
    .hero-img-container {
        display: grid;
    }
}

/* left panel cell */
.hero-left-panel {
    grid-area: hero-left-panel;
    list-style: none !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--unit6) var(--unit5) 0;
    display: none;
    height: 100%;
    position: relative;
    font-weight: 500;
}

@media screen and (min-width: 992px) {
    .hero-left-panel {
        display: flex;
    }
}

/* inner elements */
.left-panel-nav {
    display: flex;
    grid-gap: var(--unit5);
    gap: var(--unit5);
    flex-direction: column;
    color: var(--purpleB);
    padding: 0;
    align-items: center;
    margin: 0;
}

.left-panel-nav li {
    -webkit-writing-mode: vertical-lr;
            writing-mode: vertical-lr;
    transform: scale(-1, -1);
    list-style: none !important;
}

.left-panel-nav li a {
    color: var(--purpleB);
    text-decoration: none;
}

.left-panel-social {
    list-style: none !important;
    padding: 0;
    display: flex;
    grid-gap: var(--unit5);
    gap: var(--unit5);
    flex-direction: column;
    margin: 0;
}

.left-panel-social li,
.left-panel-social li a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-panel-social li a:hover {
    transform: scale(1.1);
}

.hero-img {
    width: 200%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform: translate(0%, -40%);
}

@media screen and (min-width: 768px) {
    .hero-img {
        width: 150%;
        transform: translate(0%, -10%);
    }
}

@media screen and (min-width: 992px) {
    .hero-img {
        width: 140%;
        transform: translate(0%, -30%);
    }
}

@media screen and (min-width: 1024px) {
    .hero-img {
        width: 130%;
        transform: translate(0%, -20%);
    }
}

@media screen and (min-width: 1366px) {
    .hero-img {
        transform: translate(0%, -30%);
    }
}

.slide-img {
    width: 100%;
    aspect-ratio: 2.4/1;
    object-fit: cover;
    border-radius: var(--unit5);
    margin-top: var(--unit4);
    box-shadow: 0 6px 12px var(--greenD-10);
}

.hero-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
        'qr-code qr-code'
        'established san-francisco';
    bottom: 0;
    grid-gap: var(--unit4);
    gap: var(--unit4);
    position: absolute;
    align-items: end;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
}

@media screen and (min-width: 1024px) {
    .hero-info {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 'established san-francisco qr-code';
    }
}

.qr-code {
    grid-area: qr-code;
    padding: 0 var(--unit6);
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .qr-code {
        padding: 0;
    }
}

.established {
    grid-area: established;
    text-align: center;
}

.san-francisco {
    grid-area: san-francisco;
    text-align: center;
}

.slide-indicators {
    display: flex;
    justify-content: center;
    grid-gap: var(--unit2);
    gap: var(--unit2);
    padding-bottom: var(--unit4);
    border-bottom: 1px solid var(--purple5);
}
.slide-indicator {
    height: 6px;
    border: 1px solid var(--purpleB);
    aspect-ratio: 1/1;
    border-radius: 50%;
}

.slide-indicator.active {
    background-color: var(--purpleB);
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
}

.carousel-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-50%);
    padding-inline: var(--unit4);
}

.slide-controls {
    display: flex;
    grid-gap: var(--unit2);
    gap: var(--unit2);
    align-items: center;
}

.slide-controls button {
    border: none;
    box-shadow: 0 6px 12px var(--greenD-10);
    aspect-ratio: 1/1;
    min-width: unset;
}

.pricing-container {
    padding: 50px 100px 0px 100px;
}

@media screen and (max-width: 480px) {
    .pricing-container {
        padding: 25px 25px;
    }
}

.offerings {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 480px) {
    .offerings {
        display: block;
    }
}

.offering-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.free-trial-badge {
    background: #ddccff;
    padding: 5px 10px;
    border-radius: 50px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.reviews-section {
    margin-top: 100px;
}

.reviews-container {
    min-height: 500px;
    grid-gap: 15px;
    gap: 15px;
}

@media screen and (min-width: 768px) {
    .reviews-container {
        display: flex;
    }
}

@media screen and (max-width: 768px) {
    .review {
        margin-bottom: 25px;
    }
}

/* login.css */

.login-screen {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 480px) {
    .login-screen {
        min-height: calc(100vh - 200px);
    }
}

@media screen and (max-width: 480px) {
    .login-screen {
        padding-top: 50px;
    }
}

.mobile-nav {
    padding: var(--unit4);
    background-color: var(--white);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 992px) {
    .mobile-nav {
        display: none;
    }
}

.mobile-nav-menu {
    background: rgba(255,255,255,0.9);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh; 
    width: 100%;
    justify-content: center;
    align-items: center;
}

.mobile-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    width: 100%;
    color: var(--purpleB);
}

.mobile-nav-list li {
padding: var(--unit4) var(--unit6);
width: 100%;
cursor: pointer;
}

.hamburger-btn {
    padding: 0 !important;
    min-width: unset;
    box-shadow: none;
    border: none;
}

.hamburger-btn-icon {
    aspect-ratio: 5/4;
    height: var(--unit4);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.hamburger-btn-icon span {
    height: var(--unit2);
    width: 100%;
    background: var(--purpleB);
    border-radius: var(--unit1);
}

.mobile-nav-close-btn {
    position: absolute;
    top: var(--unit4);
    right: var(--unit5);
}
/* plant-selection.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.plant-selection-grid {
    display: grid;
    grid-gap: var(--unit4);
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.limit-message {
    grid-gap: var(--unit3);
    gap: var(--unit3);
    flex-wrap: wrap;
    margin-bottom: var(--unit4);
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 640px) {
    .limit-message {
        margin-bottom: var(--unit3);
    }
}
.selection-indicator {
    flex-grow: 1;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.section1-container {
    padding: 100px 0px;
}
/* garden-beds.css */

#products-and-services {
  padding: var(--unit5) var(--unit4);
  display: grid;
  grid-gap: var(--unit6);
}

@media screen and (min-width: 1366px) {
  #products-and-services {
    padding: var(--unit6) 0;
  }
}

/* #products-and-services section {
    max-width: var(--unit10);
} */

#products-and-services .hero-section, #assessments-section {
  grid-gap: var(--unit5);
  gap: var(--unit5);
  display: grid;
}

#products-and-services .hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.size-img {
    margin-block: -15%;
    mix-blend-mode: multiply;
    width: 100%;
}

#sizes-section {
    display: grid;
    grid-gap: var(--unit6);
}

.pricing-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pricing-row > div {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.size-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobile-section-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.mobile-section-img img {
  width: 100%;
  max-width: 240px;
  height: auto;
}

.mobile-section-list {
  display: grid;
  grid-gap: var(--unit5);
  margin-top: var(--unit6);
}

section {
  max-width: 1200px;
  margin: 0 auto;
}
/* how-it-works.css */

.how-it-works {
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 992px) {
  .how-it-works {
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--unit6);
    gap: var(--unit6);
  }

  .how-it-works-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: var(--unit6);
  }
    
}

/* reschedule.css */

#reschedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: var(--unit9);  
    margin: 0 auto;
    padding-top: var(--unit5);
}

.harvest-img {
    width: 100%;
    max-width: var(--unit9);
    margin: 0 auto var(--unit5) auto;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.reschedule-button-container {
    position: relative
}
.section1-container {
    padding: 100px 0px;
}
.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider {
    -webkit-appearance: none; /* Override default CSS styles */
    -moz-appearance: none;
         appearance: none;
    width: 100%;
    height: 10px;
    background: #ddd; /* Track color */
    outline: none; /* Remove outline */
    transition: opacity 0.2s; /* Smooth transition */
    padding: 0px;
    border: none;
    box-shadow: none;
}

.slider::-moz-range-track {
    width: 100%;
    height: 10px;
    background: #2196f3; /* Track color */
    border-radius: 5px; /* Rounded corners */
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Knob width */
    height: 25px; /* Knob height */
    background: var(--purpleB); /* Knob color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 50%; /* Round knob */
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2); /* Shadow for better visibility */
    -webkit-transition: background 0.2s;
    transition: background 0.2s; /* Smooth transition */
}

.slider::-moz-range-thumb {
    width: 25px; /* Knob width */
    height: 25px; /* Knob height */
    background: #4caf50; /* Knob color */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 50%; /* Round knob */
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2); /* Shadow for better visibility */
    -moz-transition: background 0.2s;
    transition: background 0.2s; /* Smooth transition */
}

.slider-value {
    font-size: 16px;
    font-weight: bold;
}

/* maintenance-report.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.badge {
    position: relative;
    left: 145px;
    top: -10px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    padding: 5px 13px;
    border-radius: 50%;
}

.bed-rows {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
}

.garden-bed {
    width: 100px;
    height: 100px;
}

.plants-container {
    max-height: 400px;
    border: 1px solid var(--gray2);
}

.harvested-plant-controls {
    background: var(--gray2);
}

.pending-action-indicator {
    background-color: lightblue;
}

/* General styles for the sections */
.section {
    padding: 10px;
    box-sizing: border-box;
}

/* Style for the container and rows */
.gallery-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px; /* Adjust as needed */
}

.row {
    display: flex;
    flex-wrap: wrap;
}

/* Style for screens larger than 960px */
@media (min-width: 960px) {
    .section {
        width: 33.33%;
    }
}

/* Style for screens smaller than 960px */
@media (max-width: 959px) {
    .section {
        width: 100%;
    }
}

.img-container {
    position: relative;
    width: 100%;
    padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3) */
    overflow: hidden;
}

.img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.modal {
    display: block;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    text-align: center;
}

.modal-content img {
    max-width: 100%;
    display: block;
    object-fit: contain;
    width: auto;
    height: auto;
}

.close {
    position: absolute;
    top: 15px;
    right: 30px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.controls button {
    padding: 10px 20px;
    margin: 0 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.controls button:hover {
    background-color: #555;
}

/* initial-planting-report.css */

.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}

.badge {
    position: relative;
    left: 145px;
    top: -10px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 40px;
    border-radius: 50%;
}

.add {
    background: var(--gray3);
    padding: 7px 9px 7px 9px;
}

.success {
    background: var(--green0);
    padding: 7px 9px 5px 9px;
}

.draft-rows {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
}

.draft {
    width: 100px;
    height: 100px;
}

.plants-container {
    max-height: 400px;
    border: 1px solid var(--gray2);
}

.harvested-plant-controls {
    background: var(--gray2);
}
.step-content {
    max-width: var(--unit9);
    margin: 0 auto;
}
.message-bubble {
    position: relative;
    background-color: var(--purple4);
    padding: 10px 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

/* General styles for the sections */
.maintenance-results-section {
    padding: 10px;
    box-sizing: border-box;
}

/* Style for the container and rows */
.maintenance-results-gallery-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px; /* Adjust as needed */
}

.maintenance-results-row {
    display: flex;
    flex-wrap: wrap;
}

/* Style for screens larger than 960px */
@media (min-width: 960px) {
    .maintenance-results-section {
        width: 100%;
    }
}

/* Style for screens smaller than 960px */
@media (max-width: 959px) {
    .maintenance-results-section {
        width: 100%;
    }
}

.maintenance-results-img-container {
    position: relative;
    width: 100%;
    padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3) */
    overflow: hidden;
}

.maintenance-results-img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.qty-input {
    padding: var(--unit4);
}

.bed-key-input {
    width: 80px;
}

/* data font adjustments */
@font-face {
    font-family: 'antarctican-mono';
    src:
        url('https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff2'),
        url('https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('woff'),
        url('https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
            format('opentype');
    font-display: auto;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
    ascent-override: 100%;
    descent-override: 70%;
    /* line-gap-override: 0%; */
}

:root {
    /* colors */
    --black: #2f3033;
    --blackTrans: rgba(0, 0, 0, 0.16);
    --blackOverlay: rgba(0, 0, 0, 0.8);
    --white: #ffffff;
    --whiteTrans: rgba(255, 255, 255, 0.95);
    --gray1: #f7f7f7;
    --gray2: #dfdfdf;
    --gray3: #737373;
    --brown1: #d5c1ac;
    --brown2: #f6f2ee;
    --brown3: #55402b;
    --green1: #21410b;
    --green2: #4d991a;
    --green3: #c7eeae;
    --red1: #ff4d4d;
    --yellow1: #ffff66;
    --orange1: #ff9900;
    --blue1: #509cff;

    /* outlines and borders */
    --outlineDefault: 1px solid var(--black);
    --outlineOffsetDefault: -1px;
    --outlineDashed: 1px dashed var(--black);
    --borderDefault: var(--outlineDefault);

    /* box shadows */
    --boxShadowDown: 0 6px 12px var(--greenD-10);
    --boxShadowUp: 0 -6px 12px var(--greenD-10);
    --boxShadowLeft: -6px 0 12px var(--greenD-10);
    --boxShadowRight: 6px 0 12px var(--greenD-10);

    --default-font: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
        Sans-Serif;
    --paragraph-font: 'pragmatica', helvetica, arial, sans-serif;
    --defaultFont: var(--default-font);
    --data-font: 'antarctican-mono', sans-serif;
    --white: #fff;
    --white-10: hsla(0, 0%, 100%, 0.1);
    --white-25: hsla(0, 0%, 100%, 0.25);
    --white-50: hsla(0, 0%, 100%, 0.5);
    --white-75: hsla(0, 0%, 100%, 0.75);
    --white-90: hsla(0, 0%, 100%, 0.9);

    --hue: 120;
    --saturation: 100%;
    --lightness: 50%;
    --alpha: 1;
    --greenE: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        var(--alpha)
    );
    --greenE-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.95
    );
    --greenE-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.9
    );
    --greenE-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.75
    );
    --greenE-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.5
    );
    --greenE-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.25
    );
    --greenE-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 45%),
        0.1
    );
    --greenE-5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.05
    );
    --greenD: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        var(--alpha)
    );
    --greenD-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.95
    );
    --greenD-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.9
    );
    --greenD-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.75
    );
    --greenD-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.5
    );
    --greenD-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.25
    );
    --greenD-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.1
    );
    --greenD-5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.05
    );
    --greenC: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        var(--alpha)
    );
    --greenC-5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.05
    );
    --greenC-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.1
    );
    --greenC-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.25
    );
    --greenC-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.5
    );
    --greenC-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.75
    );
    --greenC-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.9
    );
    --greenC-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.95
    );

    --greenB: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        var(--alpha)
    );
    --greenB-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.1
    );
    --greenB-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.25
    );
    --greenB-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.5
    );
    --greenB-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.75
    );
    --greenB-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.9
    );
    --greenB-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 20%),
        0.95
    );
    --greenA: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) - 10%),
        var(--alpha)
    );
    --green0: hsla(
        var(--hue),
        var(--saturation),
        var(--lightness),
        var(--alpha)
    );
    --green1: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 10%),
        var(--alpha)
    );
    --green2: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 20%),
        var(--alpha)
    );
    --green3: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 30%),
        var(--alpha)
    );

    --green4: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        var(--alpha)
    );
    --green4-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.1
    );
    --green4-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.25
    );
    --green4-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.5
    );
    --green4-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.75
    );
    --green4-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.9
    );
    --green4-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.95
    );

    --green5: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        var(--alpha)
    );
    --green5-10: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.1
    );
    --green5-25: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.25
    );
    --green5-50: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.5
    );
    --green5-75: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.75
    );
    --green5-90: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.9
    );
    --green5-95: hsla(
        var(--hue),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.95
    );

    --purpleE: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 45%),
        var(--alpha)
    );

    --purpleD: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        var(--alpha)
    );
    --purpleD-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.1
    );
    --purpleD-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.25
    );
    --purpleD-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.5
    );
    --purpleD-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.75
    );
    --purpleD-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.9
    );
    --purpleD-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 40%),
        0.95
    );

    --purpleC: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        var(--alpha)
    );
    --purpleC-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        10%
    );
    --purpleC-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        25%
    );
    --purpleC-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        50%
    );
    --purpleC-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        75%
    );
    --purpleC-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        90%
    );

    --purpleB: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 20%),
        var(--alpha)
    );
    --purpleB-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.95
    );
    --purpleB-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.9
    );
    --purpleB-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 30%),
        0.75
    );
    --purpleA: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) - 10%),
        var(--alpha)
    );
    --purple0: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        var(--lightness),
        var(--alpha)
    );
    --purple1: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 10%),
        var(--alpha)
    );
    --purple2: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 20%),
        var(--alpha)
    );

    --purple3: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        var(--alpha)
    );
    --purple3-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.1
    );
    --purple3-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.25
    );
    --purple3-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.5
    );
    --purple3-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.75
    );
    --purple3-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.9
    );
    --purple3-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 30%),
        0.95
    );

    --purple4: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        var(--alpha)
    );
    --purple4-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.1
    );
    --purple4-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.25
    );
    --purple4-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.5
    );
    --purple4-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.75
    );
    --purple4-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.9
    );
    --purple4-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 40%),
        0.95
    );

    --purple5: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        var(--alpha)
    );
    --purple5-10: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.1
    );
    --purple5-25: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.25
    );
    --purple5-50: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.5
    );
    --purple5-75: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.75
    );
    --purple5-90: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.9
    );
    --purple5-95: hsla(
        calc(var(--hue) + 140),
        var(--saturation),
        calc(var(--lightness) + 45%),
        0.95
    );

    --blue0: hsla(
        calc(var(--hue) + 90),
        var(--saturation),
        var(--lightness),
        var(--alpha)
    );

    --blue1: hsla(
        calc(var(--hue) + 90),
        var(--saturation),
        calc(var(--lightness) + 10%),
        var(--alpha)
    );

    --unitScaleRatio: 2;
    --fontScaleRatio: calc(1 + (1 / 3));
    --headerLineHeight: calc(1 - (1 / 6));
    --paragraphLineHeight: calc(1 + (1 / 3));

    --unit0: 1px;
    --unit1: calc(var(--unit0) * var(--unitScaleRatio));
    --unit2: calc(var(--unit1) * var(--unitScaleRatio));
    --unit3: calc(var(--unit2) * var(--unitScaleRatio));
    --unit4: calc(var(--unit3) * var(--unitScaleRatio));
    --unit5: calc(var(--unit4) * var(--unitScaleRatio));
    --unit6: calc(var(--unit5) * var(--unitScaleRatio));
    --unit7: calc(var(--unit6) * var(--unitScaleRatio));
    --unit8: calc(var(--unit7) * var(--unitScaleRatio));
    --unit9: calc(var(--unit8) * var(--unitScaleRatio));

    --h7: calc(var(--unit4) / var(--fontScaleRatio));
    --h6: calc(var(--unit4));
    --h5: calc(var(--h6) * var(--fontScaleRatio));
    --h4: calc(var(--h5) * var(--fontScaleRatio));
    --h3: calc(var(--h4) * var(--fontScaleRatio));
    --h2: calc(var(--h3) * var(--fontScaleRatio));
    --h1: calc(var(--h2) * var(--fontScaleRatio));

    --fontSizeLabel: calc(var(--fontSizeMin) / var(--fontScaleRatio));
    --whole: 100%;
    --half: 50%;
    --third: calc(var(--whole) / 3);
    --twoThirds: 0.66667;
    --quarter: 25%;
    --fourth: 25%;
    --threeQuarters: 75%;
    --threeFourths: 75%;
    --fifth: 20%;
    --twoFifths: 40%;
    --threeFifths: 60%;
    --fourFifths: 80%;
    --sixth: calc(var(--whole) / 6);
    --fiveSixths: calc(var(--sixth) * 5);
    --seventh: calc(var(--whole) / 7);
    --twoSevenths: calc(var(--seventh) * 2);
    --threeSevenths: calc(var(--seventh) * 3);
    --fourSevenths: calc(var(--seventh) * 4);
    --fiveSevenths: calc(var(--seventh) * 5);
    --sixSevenths: calc(var(--seventh) * 6);
    --eighth: 12.5%;
    --threeEighths: calc(var(--eighth) * 3);
    --fiveEigths: calc(var(--eighth) * 5);
    --sevenEighths: calc(var(--eighth) * 7);
    --ninth: calc(var(--whole) / 9);
    --twoNinths: calc(var(--ninth) * 2);
    --fourNinths: calc(var(--ninth) * 4);
    --fiveNinths: calc(var(--ninth) * 5);
    --sevenNinths: calc(var(--ninth) * 7);
    --eightNinths: calc(var(--ninth) * 8);
    --tenth: 10%;
    --eleventh: calc(var(--whole) / 11);
    --twelvth: calc(var(--whole) / 12);
    --thirteenth: calc(var(--whole) / 13);
    --fourteenth: calc(var(--whole) / 14);
    --fifteenth: calc(var(--whole) / 15);
    --sixteenth: calc(var(--whole) / 16);

    --breakpoint-xs: 480px;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1280px;
    --breakpoint-hg: 1366px;
    --breakpoint-xh: 1440px;
    --breakpoint-gi: 1536px;
    --breakpoint-xg: 1680px;
    --breakpoint-mg: 1920px;
    --breakpoint-mgXL: 2560px;
    color: hsla(
        120,
        100%,
        calc(50% - 45%),
        0.9
    );
    color: var(--greenE-90);
    font-family: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
        Sans-Serif;
    font-family: var(--defaultFont);
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.cursive {
    font-family: 'Cedarville Cursive', cursive !important;
}

body {
    margin: 0px;
    background-color: #fff;
    background-color: var(--white);
    font-family: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
        Sans-Serif;
    font-family: var(--defaultFont);
}

body * {
    box-sizing: border-box;
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

a {
    cursor: pointer;
    color: hsla(
        calc(120 + 90),
        100%,
        calc(50% + 10%),
        1);
    color: var(--blue1);
    font: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
        Sans-Serif;
    font: var(--default-font);
}

a.small {
    font-size: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2));
    font-size: var(--h6);
}

::-webkit-scrollbar {
    width: calc(calc(calc(1px * 2) * 2) * 2);
    width: var(--unit3); /* Remove scrollbar space */
    height: calc(calc(calc(1px * 2) * 2) * 2);
    height: var(--unit3); /* Remove scrollbar space */
    background: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    );
    background: var(--greenD-10); /* Make scrollbar Y invisible */
}

::-webkit-scrollbar-thumb {
    background: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    );
    background: var(--greenD-10);
    border-radius: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    border-radius: var(--unit4);
}

/* padding */
.p0 {
    padding: 0px !important;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p15,
.p4 {
    padding: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding: var(--unit4);
}

.p20 {
    padding: 20px;
}

.p25 {
    padding: 25px;
}

.p50 {
    padding: 50px;
}

.p100 {
    padding: 100px;
}

.pt0 {
    padding-top: 0px !important;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt15,
.pt4 {
    padding-top: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding-top: var(--unit4);
}

.pt20 {
    padding-top: 20px;
}

.pt25 {
    padding-top: 25px !important;
}

.pt35 {
    padding-top: 35px !important;
}

.pt50 {
    padding-top: 50px;
}

.pt75 {
    padding-top: 75px;
}

.pt100 {
    padding-top: 100px;
}

.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl15,
.pl4 {
    padding-left: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding-left: var(--unit4);
}

.pl20 {
    padding-left: 20px;
}

.pl25 {
    padding-left: 25px;
}

.pl50 {
    padding-left: 50px;
}

.pb0 {
    padding-bottom: 0px !important;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15,
.pb4 {
    padding-bottom: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding-bottom: var(--unit4);
}

.pb20 {
    padding-bottom: 20px;
}

.pb25 {
    padding-bottom: 25px;
}

.pb50 {
    padding-bottom: 50px;
}

.pb75 {
    padding-bottom: 75px;
}

.pb100 {
    padding-bottom: 100px;
}

.pr5 {
    padding-right: 5px;
}

.pr10 {
    padding-right: 10px;
}

.pr15,
.pr4 {
    padding-right: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding-right: var(--unit4);
}

.pr20 {
    padding-right: 20px;
}

.pr25 {
    padding-right: 25px;
}

.pr50 {
    padding-right: 50px;
}

/* margin */

.m0 {
    margin: 0px;
}

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.m15,
.m4 {
    margin: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    margin: var(--unit4);
}

.m20 {
    margin: 20px;
}

.m25 {
    margin: 25px;
}

.m50 {
    margin: 50px;
}

.mt0 {
    margin-top: 0px;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt4,
.mt15 {
    margin-top: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    margin-top: var(--unit4); /* used to be 15px */
}

@media (max-width: 600px) {
    .mt3-on-mobile {
        margin-top: 15px;
    }
}

.mt20 {
    margin-top: 20px;
}

.mt25 {
    margin-top: 25px;
}

.mt50 {
    margin-top: 50px;
}

.mt75 {
    margin-top: 75px;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml15,
.ml4 {
    margin-left: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    margin-left: var(--unit4);
}

.ml20 {
    margin-left: 20px;
}

.ml25 {
    margin-left: 25px;
}

.ml50 {
    margin-left: 50px;
}

.mb0 {
    margin-bottom: 0px;
}

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15,
.mb4 {
    margin-bottom: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    margin-bottom: var(--unit4);
}

.mb20 {
    margin-bottom: 20px;
}

.mb25 {
    margin-bottom: 25px;
}

.mb50 {
    margin-bottom: 50px;
}

.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px !important;
}

.mr15 {
    margin-right: 15px;
}

@media (max-width: 600px) {
    .no-mr15-on-mobile {
        margin-right: 0px;
    }
}

.mr20 {
    margin-right: 20px;
}

.mr25 {
    margin-right: 25px;
}

.mr50 {
    margin-right: 50px;
}

/* font-size */
.text-small {
    font-size: 0.875rem !important;
}

/* text alignment */
.text-center {
    text-align: center;
}

.text-left {
    text-align: center;
}

.text-right {
    text-align: right;
}

/* block */
.block {
    display: block !important;
}

.inline-block {
    display: inline-block;
}

/* positioning */
.relative {
    position: relative !important;
}

.absolute {
    position: absolute !important;
}

.fixed {
    position: fixed !important;
}

.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.top0 {
    top: 0;
}

/* centering with flexbox */
.flex {
    display: flex;
}

@media (max-width: 600px) {
    .no-flex-on-mobile {
        display: inline;
        display: initial;
    }
}

.flex-justify-center,
.flex-center-x {
    /* centers elements horizontally */
    justify-content: center;
    display: flex;
}

.flex-align-center,
.flex-center-y {
    /* centers elements vertically */
    align-items: center;
    display: flex;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-center {
    /* centers elements vertically and horizontally */
    justify-content: center;
    align-items: center;
    display: flex;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-unitaround {
    display: flex;
    justify-content: unitaround;
}
.flex-unitbetween {
    display: flex;
    justify-content: unitbetween;
}

.flex-grow {
    flex-grow: 1;
}

.flex-1 {
    flex: 1 1;
}

.align-self-center {
    align-self: center;
}

/* makes an element appear disabled or faded */
.disabled {
    opacity: 0.4;
    pointer-events: none;
}

/* text transforms */
.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.regularcase {
    text-transform: none;
}

.link {
    color: hsla(
        calc(120 + 90),
        100%,
        calc(50% + 10%),
        1);
    color: var(--blue1);
    cursor: pointer;
    font-size: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2));
    font-size: var(--h6);
}

/* text truncation */
.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* overflow */
.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

/* outlines */
.no-outline {
    outline-width: 0px;
}

.no-underline {
    text-decoration: none;
}

.underline-on-hover:hover {
    text-decoration: underline;
}

/* floats */
.float-right {
    float: right !important;
}

.float-left {
    float: left;
}

/* background colors */
.bg-white {
    background-color: #fff !important;
    background-color: var(--white) !important;
}

.bg-green1 {
    background-color: hsla(
        120,
        100%,
        calc(50% + 10%),
        1) !important;
    background-color: var(--green1) !important;
}

.bg-green2 {
    background-color: hsla(
        120,
        100%,
        calc(50% + 20%),
        1);
    background-color: var(--green2);
}

.bg-green3 {
    background-color: hsla(
        120,
        100%,
        calc(50% + 30%),
        1);
    background-color: var(--green3);
}

.bg-gray1 {
    background-color: #f7f7f7 !important;
    background-color: var(--gray1) !important;
}

.bg-gray2 {
    background-color: #dfdfdf;
    background-color: var(--gray2);
}

.bg-gray3 {
    background-color: #737373;
    background-color: var(--gray3);
}

.bg-gray4 {
    background-color: '#E6E5EA';
}

.bg-brown1 {
    background-color: #d5c1ac !important;
    background-color: var(--brown1) !important;
}

.bg-brown2 {
    background-color: #f6f2ee !important;
    background-color: var(--brown2) !important;
}

.bg-brown3 {
    background-color: #55402b !important;
    background-color: var(--brown3) !important;
}

.bg-yellow1 {
    background-color: #ffff66 !important;
    background-color: var(--yellow1) !important;
}

.bg-orange1 {
    background-color: #ff9900 !important;
    background-color: var(--orange1) !important;
}

.bg-blue1 {
    background-color: hsla(
        calc(120 + 90),
        100%,
        calc(50% + 10%),
        1) !important;
    background-color: var(--blue1) !important;
}

.bg-red1 {
    background-color: #ff4d4d !important;
    background-color: var(--red1) !important;
}

.bg-green0 {
    background-color: hsla(
        120,
        100%,
        50%,
        1);
    background-color: var(--green0);
}

.bg-purpleB90 {
    background-color: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 30%),
        0.9
    ) !important;
    background-color: var(--purpleB-90) !important;
}

/* text colors */
.text-white {
    color: #fff !important;
    color: var(--white) !important;
}

.text-black {
    color: #2f3033 !important;
    color: var(--black) !important;
}

.text-red {
    color: red !important;
}

.text-green1 {
    color: hsla(
        120,
        100%,
        calc(50% + 10%),
        1);
    color: var(--green1);
}

.text-green2 {
    color: hsla(
        120,
        100%,
        calc(50% + 20%),
        1);
    color: var(--green2);
}

.text-green3 {
    color: hsla(
        120,
        100%,
        calc(50% + 30%),
        1);
    color: var(--green3);
}

.text-gray1 {
    color: #f7f7f7;
    color: var(--gray1);
}

.text-gray2 {
    color: #dfdfdf;
    color: var(--gray2);
}

.text-gray3 {
    color: #737373;
    color: var(--gray3);
}

.text-brown1 {
    color: #d5c1ac !important;
    color: var(--brown1) !important;
}

.text-brown2 {
    color: #f6f2ee;
    color: var(--brown2);
}

.text-brown3 {
    color: #55402b !important;
    color: var(--brown3) !important;
}

.text-yellow1 {
    color: #ffff66;
    color: var(--yellow1);
}

.text-red1 {
    color: #ff4d4d !important;
    color: var(--red1) !important;
}

.text-blue1 {
    color: hsla(
        calc(120 + 90),
        100%,
        calc(50% + 10%),
        1) !important;
    color: var(--blue1) !important;
}

.text-purple0 {
    color: hsla(
        calc(120 + 140),
        100%,
        50%,
        1);
    color: var(--purple0);
}

.text-purpleB90 {
    color: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 30%),
        0.9
    );
    color: var(--purpleB-90);
}

.text-purpleB {
    color: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    color: var(--purpleB);
}

.text-green0 {
    color: hsla(
        120,
        100%,
        50%,
        1);
    color: var(--green0);
}

/* border colors */
.border-bottom-purpleC-10 {
    border-bottom: 1px solid hsla(
        calc(120 + 140),
        100%,
        calc(50% - 30%),
        10%
    ) !important;
    border-bottom: 1px solid var(--purpleC-10) !important;
}

/* cursor */
.pointer {
    cursor: pointer;
}

/* z-index */
.z0 {
    z-index: 0;
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

.z3 {
    z-index: 3 !important;
}

.z4 {
    z-index: 4 !important;
}

/* box-shadow */
.shadow {
    box-shadow: 0 6px 12px hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    ) !important;
    box-shadow: var(--boxShadowDown) !important;
}

/* width */
.full {
    width: 100% !important;
}

@media (max-width: 600px) {
    .full-on-mobile {
        width: 100% !important;
    }
}

.full-span {
    width: 100vw !important;
}

.fit-content {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}

/* height */
.full-height {
    height: 100%;
}

.full-view {
    height: 100vh;
}

.min-full-view {
    min-height: 100vh;
}

.half {
    width: 50% !important;
}

.fit-content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.max-content {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
}

/* border-radius */
.round {
    border-radius: 50%;
}

.curved {
    border-radius: 5px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container input {
    width: auto !important;
    border: none;
    /* text-align: center; */
}

/* opacity  */
.opacity01 {
    opacity: 0.1;
}

.opacity02 {
    opacity: 0.2;
}

.opacity03 {
    opacity: 0.3;
}

.opacity04 {
    opacity: 0.4;
}

.opacity05 {
    opacity: 0.5;
}

.opacity06 {
    opacity: 0.6;
}

.opacity07 {
    opacity: 0.7;
}

.opacity08 {
    opacity: 0.8;
}

.opacity09 {
    opacity: 0.9;
}

/* font-weigth */
.fw100 {
    font-weight: 100 !important;
}

section {
    max-width: 1200px;
    margin: 0 auto;
    padding-inline: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding-inline: var(--unit4);
}

@media screen and (min-width: 480px) {
    section {
        padding-inline: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
        padding-inline: var(--unit5);
    }
}

@media screen and (min-width: 992px) {
    section {
        padding-inline: calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2);
        padding-inline: var(--unit6);
    }
}

@media screen and (min-width: 1260px) {
    section {
        padding-inline: 0;
    }
}

/* app  */
.section1-title {
    font-size: 60px;
}

.dollar-sign {
    position: relative;
    top: -12px;
}

.dollar-sign-2 {
    position: relative;
    top: -6px;
}

.content-container {
    display: flex;
    grid-gap: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    gap: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    grid-gap: var(--unit4);
    gap: var(--unit4);
    align-items: flex-end;
}

.app-root header {
    grid-area: header;
}

.app-root main {
    grid-area: main;
}

.app-root footer {
    grid-area: footer;
}

.app-root {
    display: grid;
    grid-template-areas:
        'header'
        'main'
        'footer';
}

.hidden {
    display: none !important;
}

.MuiInput-underline {
    font-family: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
        Sans-Serif !important;
    font-family: var(--default-font) !important;
}

.MuiInput-underline:before {
    border-bottom: unset !important;
}

.MuiInput-underline:after {
    border-bottom: unset !important;
}

button {
    background: hsla(
        120,
        100%,
        50%,
        1);
    background: var(--green0);
    border: 1px solid hsla(
        120,
        100%,
        calc(50% + 20%),
        1);
    border: 1px solid var(--green2);
    border-top-color: hsla(
        120,
        100%,
        calc(50% + 30%),
        1);
    border-top-color: var(--green3);
    border-bottom-color: hsla(
        120,
        100%,
        calc(50% - 10%),
        1);
    border-bottom-color: var(--greenA);
    padding: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    padding: var(--unit4) var(--unit5);
    border-radius: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    border-radius: var(--unit5);
    text-transform: capitalize;
    box-shadow: 0 3px 6px
        hsla(
            120,
            100%,
            calc(50% - 10%),
            calc(1 - 0.85)
        );
    box-shadow: 0 3px 6px
        hsla(
            var(--hue),
            var(--saturation),
            calc(var(--lightness) - 10%),
            calc(var(--alpha) - 0.85)
        );
    font-weight: 600;
    color: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    color: var(--purpleB);
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

button:hover {
    background: hsla(
        120,
        100%,
        calc(50% + 20%),
        1);
    background: var(--green2);
    transform: scale(1.025);
}

button:active {
    transform: scale(1);
}

button.small {
    padding: calc(calc(calc(1px * 2) * 2) * 2) calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding: var(--unit3) var(--unit4);
    font-size: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2));
    font-size: var(--h6);
    border-radius: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    border-radius: var(--unit5);
    text-transform: capitalize;
    min-width: calc(calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2) + calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2));
    min-width: calc(var(--unit6) + var(--unit5));
    display: flex;
    justify-content: center;
    align-items: center;
}

button.btn3 {
    border-color: hsla(0, 0%, 100%, 0.5);
    border-color: var(--white-50);
    border-top-color: #fff;
    border-top-color: var(--white);
    border-bottom-color: hsla(
        120,
        100%,
        calc(50% - 30%),
        0.1
    );
    border-bottom-color: var(--greenC-10);
}

button.purple {
    background: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    background: var(--purpleB);
    color: white;
}

button.purple:hover {
    background-color: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 10%),
        1);
    background-color: var(--purpleA);
}

button.btn2,
button.btn3,
input::file-selector-button {
    background: white;
}

button.btn4 {
    background: white;
    border: none;
    box-shadow: none;
}

data {
    font-family: 'antarctican-mono', sans-serif;
    font-family: var(--data-font);
}

p {
    font-family: 'pragmatica', helvetica, arial, sans-serif;
    font-size: calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2)) * calc(1 + (1 / 3)));
    font-size: var(--h5);
    line-height: calc(3 / 2);
    margin-top: 0;
}

small {
    font-family: 'pragmatica', helvetica, arial, sans-serif;
    font-size: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2));
    font-size: var(--h6);
    line-height: calc(3 / 2);
    display: block;
}

small + small,
p + p {
    margin-bottom: calc(1em * 3 / 2);
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1;
    margin: 0;
    padding: 0;
    /* color: var(--purpleC); */
}

/* auto grid */
/* auto grid */

.auto-grid-5,
.auto-grid-small,
.auto-grid,
.auto-grid-medium,
.auto-grid-6,
.auto-grid-large,
.auto-grid-7 {
    display: grid;
    grid-gap: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    grid-gap: var(--unit5);
}

.auto-grid-5,
.auto-grid-small {
    grid-gap: calc(calc(1px * 2) * 2);
    grid-gap: var(--unit2);
    grid-template-columns: repeat(auto-fill, minmax(calc(calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2) * 2), 1fr));
    grid-template-columns: repeat(auto-fill, minmax(var(--unit7), 1fr));
}

.auto-grid,
.auto-grid-6,
.auto-grid-medium {
    grid-template-columns: repeat(
        auto-fill,
        minmax(calc(calc(calc(calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2) * 2) * 2) + calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2)), 1fr)
    );
    grid-template-columns: repeat(
        auto-fill,
        minmax(calc(var(--unit8) + var(--unit6)), 1fr)
    );
}

.auto-grid-7,
.auto-grid-large {
    grid-template-columns: 1fr;
    grid-gap: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    grid-gap: var(--unit5);
}

@media screen and (min-width: 786px) {
    .auto-grid-7,
    .auto-grid-large {
        grid-template-columns: 1fr 1fr;
        grid-gap: calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2);
        grid-gap: var(--unit6);
    }
}

select {
    padding: calc(calc(calc(1px * 2) * 2) * 2) calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) calc(calc(calc(1px * 2) * 2) * 2) calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding: var(--unit3) var(--unit5) var(--unit3) var(--unit4);
    border-radius: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    border-radius: var(--unit5);
    border-bottom: none;
    box-shadow: 0 6px 12px hsla(
        calc(120 + 140),
        100%,
        calc(50% - 40%),
        0.1
    );
    box-shadow: 0 6px 12px var(--purpleD-10);
    background-color: white;
    color: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 40%),
        0.75
    );
    color: var(--purpleD-75);
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2);
    min-width: var(--unit6);
}

input,
textarea {
    font-family: 'pragmatica', helvetica, arial, sans-serif;
    font-family: var(--paragraph-font);
}

input[type='date'] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type='date']:before {
    content: attr(placeholder) !important;
    color: hsla(
        120,
        100%,
        calc(50% - 30%),
        0.25
    );
    color: var(--greenC-25);
}

input[type='date']:focus:before,
input[type='date']:valid:before {
    content: '';
}

input:focus {
    outline: 2px solid hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    outline: 2px solid var(--purpleB);
    outline: offset;
    outline-offset: -1px;
    background: hsla(
        120,
        100%,
        calc(50% + 40%),
        1) !important;
    background: var(--green4) !important;
}

textarea:focus {
    outline: 2px solid hsla(
        calc(120 + 140),
        100%,
        50%,
        1);
    outline: 2px solid var(--purple0);
    outline: offset;
    outline-offset: -1px;
    background: hsla(
        120,
        100%,
        calc(50% + 40%),
        1);
    background: var(--green4);
}

::-webkit-input-placeholder,
input[type='date']::-webkit-input-placeholder {
    color: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    color: var(--greenD-25);
}

::-ms-input-placeholder,
input[type='date']::-ms-input-placeholder {
    color: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    color: var(--greenD-25);
}

::-webkit-input-placeholder, input[type='date']::-webkit-input-placeholder {
    color: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    color: var(--greenD-25);
}

::-ms-input-placeholder, input[type='date']::-ms-input-placeholder {
    color: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    color: var(--greenD-25);
}

::placeholder,
input[type='date']::placeholder {
    color: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    color: var(--greenD-25);
}

a:has(button) {
    text-decoration: none;
}

.p-none {
    padding: none;
}

.p0 {
    padding: 1px;
    padding: var(--unit0);
}

.p1 {
    padding: calc(1px * 2);
    padding: var(--unit1);
}

.p2 {
    padding: calc(calc(1px * 2) * 2);
    padding: var(--unit2);
}

.p3 {
    padding: calc(calc(calc(1px * 2) * 2) * 2);
    padding: var(--unit3);
}

.p4 {
    padding: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding: var(--unit4);
}

.p5 {
    padding: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    padding: var(--unit5);
}

.p6 {
    padding: calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2);
    padding: var(--unit6);
}

.p7 {
    padding: calc(calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2) * 2);
    padding: var(--unit7);
}

.p8 {
    padding: calc(calc(calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2) * 2) * 2);
    padding: var(--unit8);
}

.p9 {
    padding: calc(calc(calc(calc(calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2) * 2) * 2) * 2) * 2);
    padding: var(--unit9);
}

hr {
    border: none;
    border-top: 1px solid hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    );
    border-top: 1px solid var(--greenD-10);
}

/* general radio button */
input[type='radio'] {
    all: unset;
    height: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    height: var(--unit5);
    width: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) * 2);
    width: var(--unit5);
    background: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    );
    background: var(--greenD-10);
    border: 2px solid hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    border: 2px solid var(--purpleB);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 6px 12px hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    box-shadow: inset 0 6px 12px var(--greenD-25);
}

input[type='radio']:hover {
    background: hsla(
        120,
        100%,
        calc(50% + 40%),
        1);
    background: var(--green4);
    transform: scale(1.05);
}

input[type='radio']:checked {
    background: hsla(
        120,
        100%,
        calc(50% + 40%),
        1) !important;
    background: var(--green4) !important;
}

input[type='radio']:checked::before {
    content: '';
    background: hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    background: var(--purpleB);
    height: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    height: var(--unit4);
    width: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    width: var(--unit4);
    position: absolute;
    border-radius: calc(calc(calc(1px * 2) * 2) * 2);
    border-radius: var(--unit3);
    box-shadow: 0 3px 6px hsla(
        calc(120 + 140),
        100%,
        calc(50% - 30%),
        50%
    );
    box-shadow: 0 3px 6px var(--purpleC-50);
}

/* general checkbox */
input[type='checkbox'] {
    all: unset;
    height: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) + calc(calc(calc(1px * 2) * 2) * 2));
    height: calc(var(--unit4) + var(--unit3));
    width: calc(calc(calc(calc(calc(1px * 2) * 2) * 2) * 2) + calc(calc(calc(1px * 2) * 2) * 2));
    width: calc(var(--unit4) + var(--unit3));
    background: hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    );
    background: var(--greenD-10);
    border: 2px solid hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    border: 2px solid var(--purpleB);
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 6px 12px hsla(
        120,
        100%,
        calc(50% - 40%),
        0.25
    );
    box-shadow: inset 0 6px 12px var(--greenD-25);
}

input[type='checkbox']:hover {
    background: hsla(
        120,
        100%,
        calc(50% + 40%),
        1);
    background: var(--green4);
    transform: scale(1.05);
}

input[type='checkbox']:checked {
    background: hsla(
        120,
        100%,
        calc(50% + 40%),
        1) !important;
    background: var(--green4) !important;
}

input[type='checkbox']:checked::before {
    content: '';
    border-bottom: 3px solid hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    border-bottom: 3px solid var(--purpleB);
    border-left: 3px solid hsla(
        calc(120 + 140),
        100%,
        calc(50% - 20%),
        1);
    border-left: 3px solid var(--purpleB);
    height: calc(calc(1px * 2) * 2);
    height: var(--unit2);
    width: calc(calc(calc(1px * 2) * 2) * 2);
    width: var(--unit3);
    transform: rotate(-45deg) scale(1.5) translate(1px, -1px);
    position: absolute;
}

.card {
    padding: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    padding: var(--unit4);
    background: #fff;
    background: var(--white);
    border-radius: calc(calc(calc(calc(1px * 2) * 2) * 2) * 2);
    border-radius: var(--unit4);
    box-shadow: 0 6px 12px hsla(
        120,
        100%,
        calc(50% - 40%),
        0.1
    );
    box-shadow: var(--boxShadowDown);
}

.flex {
    display: flex;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-center-y {
    align-items: center;
}




label {
  /* font-size: 13px; */
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
}

summary{
  outline: none;
}

button:focus {
  outline: 0;
} 

/* 3rt party styles */
.MuiInputBase-root-254 {
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
}

@media(max-width: 400px) {
  .mobile-settings .MuiTableCell-root-279 {
    padding: 0px !important;
  }  
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
}

.react-datepicker-wrapper, .react-datepicker__input-container input {
  width: 100%;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--green1) !important;
}

.MuiSwitch-track {
  background-color: var(--gray3) !important;
}

.MuiButton-textPrimary {
  color: var(--black) !important;
}
/* 
.date-container {
  border: 1px solid #CCCCCC;
  padding: 5px 10px;
} */

.date-container .MuiInput-underline {
  width: 100%;
}

/* .date-container .MuiInput-underline:before {
  border: none !important;
}

.date-container .MuiInput-underline:after {
  border: none !important;
}

.date-container .MuiInput-underline:hover {
  border: none !important;
} */

.MuiDataGrid-root .MuiDataGrid-main {
  position: static !important;
  position: initial !important;
}

/* helpers styles */
.full-width {
  width: 100% !important;
}

.invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

/* Separate rule for compatibility, :focus-within is required on modern Firefox and Chrome */
input.invisible:focus + label {
  outline: thin dotted;
}
input.invisible:focus-within + label {
  outline: thin dotted;
}

.center {
  text-align: 'center'
}

.link {
  color: #4C9AFF !important;
}

.link:hover {
  text-decoration: underline !important;
}

.footer-link {
  color: '#F6F2EE' !important;
}

.footer-link:hover {
  text-decoration: underline !important;
}

.chatbot-toggle {
  position: fixed;
  bottom: var(--unit4);
  right: var(--unit4);
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1000;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  border-bottom-left-radius: var(--unit4) !important;
}
.chatbot-open-toggle {
  position: fixed;
  top: calc(61.38px + var(--unit5));
  right: var(--unit5);
  z-index: 1;
}
.unread-badge {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  height: calc(var(--unit4) + var(--unit3));
  background-color: red;
  color: var(--white);
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  transform-origin: bottom center;
  -webkit-animation: newMessageAnimation 0.4s ease-in-out;
          animation: newMessageAnimation 0.4s ease-in-out;
}

@-webkit-keyframes newMessageAnimation {
  0% {
    opacity: 0;
    transform: scale(0) translateY(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1) translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes newMessageAnimation {
  0% {
    opacity: 0;
    transform: scale(0) translateY(0);
  }
  50% {
    opacity: 1;
    transform: scale(1.1) translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

